@import '../variables';
@import '../mixins/transition';
@import '../mixins/flex';
@import '../mixins/transform';
@import '../mixins/transform-origin';

/*Header*/
header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1001;
    color: $text-color;
    text-transform: uppercase;
}

#header-wrapper {
    &.with-bg {
        background-color: rgba(#ffffff, .9);
    }
}

/*Desktop header*/
.desktop-header {
    position: relative;
    top: 0;
    padding: 2.3rem 0;
    background-color: transparent;
    @include transition(all $transition-time);
    @media (max-width: 1199px) {
        .own-container-fluid {
            padding-left: 1%;
            padding-right: 1%;
        }
    }

    .header-middle {
        position: relative;
        @include transition(all $transition-time);
    }

    .logo {
        height: auto;
        text-align: center;

        a {
            display: block;
            height: inherit;
            overflow: hidden;
        }

        img {
            width: 15rem;
            @include transition(all $transition-time);
            @media (max-width: 1199px) {
                width: 11rem;
            }
        }
    }

    @media (max-width: 991px) {
        display: none;
    }
}

#desktop-menu {
    position: relative;
    list-style: none;
    margin: 0 1.5rem 0 0;
    padding: 0;
    @include transition(height 0.3s);
    @include flex(4);
    @media (max-width: 1500px) {
        margin: 0 .8rem 0 0;
    }

    & > li {
        display: block;
        position: relative;
        margin: 0;
        color: $text-color;

        a, .a {
            position: relative;
            display: block;
            cursor: pointer;
            color: inherit;
            font-weight: 900;
            font-size: 1.1rem;
            padding: .5rem 1.5rem;
            text-align: center;
            @include transition(color .3s);
            @media (max-width: 1500px) {
                padding: .5rem .8rem;
                font-size: 1rem;
            }
            @media (max-width: 1199px) {
                padding: .5rem .6rem;
                font-size: .95rem;
            }
        }

        &:hover {
            & > a, & > .a {
                &::after {
                    opacity: 1;
                    @include transform(translateY(0));
                }
            }
        }

        &.active {
            color: $primary-color;
        }
    }

    .menu-dropdown {
        li {
            a, .a {
                text-transform: lowercase;
                font-weight: 700;
                font-size: 1.2rem;
                margin: 0 1.8rem;
                text-align: left;
                padding: 0;
                @include transition(opacity .3s);
            }
        }
    }
}

.menu-item-dropdown-toggle {
    position: relative;
    cursor: pointer;
    a {
        &::after {
            display: none;
        }
    }

    &:hover {
        .menu-dropdown {
            visibility: visible;
            @include transform(translate3d(-50%, 0, 0));
            opacity: 1;
        }
    }

    .menu-dropdown-toggle-mobile {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 1.3rem;
    }
}

.menu-dropdown {
    position: absolute;
    left: 50%;
    display: block;
    list-style: none;
    margin: 0;
    padding: .8rem 0;
    color: $text-color;
    background: $base-color;
    border-radius: 0 0 2rem 2rem;
    cursor: auto;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    @media(min-width: 768px) {
        white-space: nowrap;
    }
    box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.15);
    @include transition(transform .3s cubic-bezier(.4, 0, .2, 1), opacity .2s, visibility .2s);
    @include transform(translate3d(-50%, 30px, 0));
    @include transform-origin(50% 0);
    z-index: 120;

    li {
        a {
            min-width: 9rem;
        }
    }

    li {
        &:hover {
            a {
                opacity: .7;
            }
        }
    }
}

/*Mobile header*/
.mobile-header {
    display: none;
    width: 100%;
    height: 100%;
    padding: 1.5rem 1rem;
    min-width: 0;
    @include transition(padding .3s);

    .logo {
        width: auto;
        height: auto;
        text-align: center;
        margin-right: 1rem;

        a {
            display: block;
            height: inherit;
            overflow: hidden;
        }

        img {
            width: 10rem;
            @include transition(all $transition-time);
        }
    }

    @media (max-width: 991px) {
        display: block;
    }
}

#header-wrapper {
    &.open {
        #nav-icon {
            .dot {
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(7),
                &:nth-child(8) {
                    opacity: 0;
                }
            }

            .dash-2 {
                opacity: 0;
            }

            .dash-1 {
                transform: rotate(-41deg);
            }

            .dash-3 {
                transform: rotate(39deg);
            }
        }
    }
}
#burger-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $base-color;
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
    z-index: 102;
    @include transition(transform .5s);
    @include transform(translateY(-105%));
    @media (min-width: 992px) {
        display: none;
    }

    &.open {
        @include transform(translateY(0));
        .menu-item {
            opacity: 1;
            transform: translateY(0);
            &:nth-child(1) {
                transition-delay: .3s;
            }
            &:nth-child(2) {
                transition-delay: .5s;
            }
            &:nth-child(3) {
                transition-delay: .7s;
            }
            &:nth-child(4) {
                transition-delay: .9s;
            }
            &:nth-child(5) {
                transition-delay: 1.1s;
            }
            &:nth-child(6) {
                transition-delay: 1.3s;
            }
        }
    }
    .burger-menu-search-bar-wrap {
        display: flex;
        align-items: center;
        margin: 0 2rem;
    }
    .search-bar {
        margin: 0;
        input {
            width: 90%;
            min-width: auto;
        }
    }
    .burger-menu-inner-close {
        position: absolute;
        top: 1rem;
        right: .5rem;
        padding: .5rem;
        cursor: pointer;
        z-index: 5;
        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
    .burger-menu-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 5rem 1rem;
        text-align: center;
        margin: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        overflow: -moz-hidden-scrollable;
        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }

        & > li {
            flex-shrink: 0;
            padding-bottom: 2rem;
            text-transform: uppercase;

            &:first-child {
                margin-top: auto;
            }

            &:last-child {
                margin-bottom: auto;
            }
        }
        li {
            color: $text-color;
            text-transform: uppercase;

            a {
                display: block;
                font-weight: 800;
                font-size: 1.6rem;
                padding: .3rem 1rem;
                margin: 0;
            }

            &.menu-item-dropdown-toggle {
                svg {
                    right: 11rem;
                    @media (max-width: 425px) {
                        right: 13rem;
                    }
                }
            }
        }
    }
    .menu-item {
        opacity: 0;
        transform: translateY(-20px);
        transition: all .3s;
    }

    .menu-dropdown {
        position: relative;
        visibility: visible;
        left: auto;
        @include transform(translate3d(0, 0, 0));
        opacity: 1;
        background: transparent;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        li {
            a {
                font-size: 1.1rem;
                font-weight: 500;
                padding: .5rem 2rem;
                margin: 0;
            }
        }
    }

    .menu-dropdown-toggle {
        .menu-dropdown-toggle-mobile {
            display: block;
            color: inherit;
            cursor: pointer;

            &.open {
                transform: rotate(180deg);
            }
        }
    }
}

.menu-item-dropdown {
    display: block;
    position: relative;
}

#nav-icon {
    width: 4rem;
    height: 3rem;
    margin-left: .5rem;
    @media (max-width: 991px) {
        width: 2rem;
        height: auto;
    }
    @include transform(rotate(0deg));
    transition: .5s ease-in-out;
    cursor: pointer;

    .dot {
        transition: all .3s;
        opacity: 1;
    }

    .dash-1,
    .dash-3 {
        transform-origin: right;
        transition: all .3s;
    }

    .dash-2 {
        transition: all .3s;
    }
}
