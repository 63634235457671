#up {
    position: fixed;
    right: 0;
    bottom: 0;
    line-height: 4rem;
    height: 4rem;
    width: 4rem;
    text-align: center;
    font-size: 1.5rem;
    border-radius: .8rem;
    margin: 1.5rem;
    background-color: $text-color;
    cursor: pointer;
    opacity: 0;
    @include box-shadow(0, 0, 16px, 0, rgba(0, 0, 0, .2));
    @include transform(translate3d(0, 8rem, 0));
    @include transition(all $transition-time);
    z-index: 999;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        top: 1.7rem;
        left: 1.25rem;
        width: 1.5rem;
        height: 1.5rem;
        border: 5px solid $base-color;
        border-top-style: none;
        border-right-style: none;
        @include transition(border-color $transition-time);
        @include transform(rotate(135deg));
    }

    &.active {
        opacity: 1;
        @include transform(translate3d(0, 0, 0));
    }

    &:hover {
        background: rgba($text-color, .58);
        &::before {
            border-color: #ffffff;
        }
    }
}
