// Variables
@import 'variables';

//Mixins
@import "mixins/mixins";

//Base
@import 'base/general';
@import 'base/header';
@import 'base/footer';
@import "base/placeholder";
@import 'base/privacy-policy';
@import 'base/print';
@import 'base/utilities';

//Elements
@import "elements/alertbox";
@import "elements/arrow-up";
@import "elements/banner";
@import 'elements/buttons';
@import 'elements/contact';
@import 'elements/forms';
@import 'elements/checkbox';
@import 'elements/breadcrumbs';
@import 'elements/headroom';
@import 'elements/utils';
@import 'elements/own-container';
@import 'elements/heading';
@import 'elements/offer';
@import 'elements/about-us';
@import 'elements/cookies-bar';
@import 'elements/cart';
@import 'elements/profile';
@import 'elements/search-bar';
@import 'elements/page-title';
@import 'elements/product';
@import 'elements/product-detail';
@import 'elements/product-ordering';
@import 'elements/banner-floated';
@import 'elements/scroll-down';
@import 'elements/newsletter';
@import 'elements/blog';
@import 'elements/blog-detail';
@import 'elements/heading-with-icon';
@import 'elements/product-grid';
@import 'elements/sidebar';
@import 'elements/page-breadcrumbs';
@import 'elements/without-banner';
@import 'elements/range-slider';
@import 'elements/swiper';
@import 'elements/product-badges';
@import 'elements/add-to-cart';
@import 'elements/pagination';
@import 'elements/lazyimg';
@import 'elements/tabs';
@import 'elements/tooltip';
@import 'elements/subcategories';
@import 'elements/technology';

//Animations
@import 'animations/animations';

//Pages
@import "pages/home";
@import "pages/eshop";
@import "pages/eshop-cart";
@import "pages/eshop-category";
@import "pages/eshop-product";
@import "pages/eshop-login";
@import "pages/eshop-register";
@import "pages/eshop-profile";
@import "pages/error";
@import "pages/document";

@import '~microtip/microtip.css';

.scale-both {max-width: 100%; max-height: 100%;}
