// LightBox
@import "~photoswipe/dist/photoswipe.css";
@import "~photoswipe/dist/default-skin/default-skin.css";

// Swiper
@import "~swiper/dist/css/swiper.min.css";

#product-detail-price {
    padding: 2rem 0;
}

.product-detail-dimensions {
    .row {
        font-size: 1.05rem;
        margin: 0;
        padding: .5rem 0;
        border-bottom: 1px solid #c2c2c2;
        &:last-child {
            border-bottom: none;
        }
    }
}
.product-add-to-cart-btn {
    display: inline-block;
    position: relative;
    text-transform: lowercase;
    font-weight: 700;
    font-size: 1.5rem;
    letter-spacing: 1px;
    background: $secondary-color;
    color: $base-color;
    padding: 0.5rem 1.2rem;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    box-shadow: none;
    white-space: nowrap;
    border-radius: 0;
    @include transition(all .3s);

    &:focus {
        outline: 0;
    }

    &:hover {
        color: $base-color;
        background-color: lighten($secondary-color, 10%);
    }
    @media (max-width: 1199px) {
        font-size: 1.3rem;
        padding: 0.5rem 1rem;
    }
}

#product-detail {
    .swiper-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        text-align: center;

        img {
            width: 100%;
            height: 100%;
            max-width: 45rem;
            object-fit: contain;
        }
    }

    .gallery-top {
        height: 23rem;
        width: 100%;
    }

    .gallery-thumbs {
        max-width: 50rem;
        height: 9rem;
        margin-top: 4rem;
        @media (max-width: 575px) {
            margin-top: 1rem;
            height: 11rem;
        }
        @media (max-width: 425px) {
            margin-top: 0;
            margin-bottom: 1rem;
            height: 8rem;
        }
        @media (max-width: 375px) {
            margin-top: 0;
            margin-bottom: 1rem;
            height: 10rem;
        }
    }

    .gallery-thumbs .swiper-slide {
        height: 100%;
        opacity: 0.4;
        cursor: pointer;
        transition: opacity .3s;
    }

    .gallery-thumbs .swiper-slide-thumb-active {
        opacity: 1;
    }
}

#product-similar {
    .product-grid-item {
        border: 1px solid #e6e6e6;
        .product-img {
            padding-top: 80%;
        }
        h3 {
            text-transform: unset;
            font-size: .9rem;
            font-weight: 700;
            background: transparent;
            color: $text-color;
            display: block;
            padding: 0;
            margin-top: 0;
            margin-bottom: 1.5rem;
        }

        &:hover {
            h3 {
                color: $text-color;
            }
        }

        .product-available {
            font-size: .9rem;
            font-weight: 600;
            line-height: 1;
            display: block;

            span {
                color: #25a314;
            }

            .quantity {
                color: $text-color;
            }
        }

        .product-footer {
            margin-top: auto;
        }

        .own-btn {
            font-size: .9rem;
            padding: .6rem 1rem;
            letter-spacing: 2px;
            border-radius: 0;
            box-shadow: none;

            &:hover {
                background: lighten($primary-color, 10%);
                color: $base-color;
            }
        }
    }
}
