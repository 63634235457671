#product-list {
    @media (max-width: 1199px) {
        .own-container-fluid {
            padding-left: 1%;
            padding-right: 1%;
        }
    }
    @media (max-width: 991px) {
        .own-container-fluid {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
.product-list-wrap {
    display: flex;
    //flex-wrap: wrap;
    justify-content: space-between;
    .content {
        width: 100%;
        margin-left: 2rem;
        overflow: hidden;
        @media (max-width: 991px) {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
        }
    }
    .product-grid {
        justify-content: flex-start;
        margin: 0 -1rem;
        .product-grid-item {
            margin: 1rem;
            border-radius: 0;
            width: calc(25% - 2rem);
            border: 1px solid #e6e6e6;
            text-align: center;
            @media (max-width: 1300px) {
                width: calc(33.33% - 2rem);
            }
            @media (max-width: 767px) {
                width: calc(50% - 2rem);
            }
            @media (max-width: 575px) {
                width: calc(100% - 2rem);
            }
            h3 {
                text-transform: unset;
                font-size: .9rem;
                font-weight: 700;
                background: transparent;
                color: $text-color;
                display: block;
                padding: 0;
                margin-top: 0;
                margin-bottom: 1.5rem;
            }
            &:hover {
                @include box-shadow(0, 0, 15px, 2px, rgba(103, 103, 103, 0.16));
                h3 {
                    color: $text-color;
                }
            }
        }
        .product {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: .5rem 1.5rem 2rem 1.5rem;
        }
        .product-img {
            padding-top: 80%;
        }

        .product-available {
            font-size: .9rem;
            font-weight: 600;
            line-height: 1;
            display: block;
            span {
                color: #25a314;
            }
            .quantity {
                color: $text-color;
            }
        }
        .product-footer {
            margin-top: auto;
        }
        .own-btn {
            font-size: .9rem;
            padding: .6rem 1rem;
            letter-spacing: 2px;
            border-radius: 0;
            box-shadow: none;
            &:hover {
                background: lighten($primary-color, 10%);
                color: $base-color;
            }
        }
    }
}
.product-grid-load-more {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
    }
}
.product-grid-counter {
    margin-top: .5rem;
    .product-grid-count {
        font-weight: 800;
    }
    span {
        font-weight: 600;
    }
}
