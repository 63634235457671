#register {
    max-width: 100rem;
    margin: auto;
    padding-top: 9rem;
    overflow: hidden;
    @media (max-width: 991px) {
        padding-top: 7rem;
    }
    h2 {
        font-size: 1.6rem;
        font-weight: 900;
        text-transform: none;
        letter-spacing: 1px;
        line-height: 1;
        color: $primary-color;
        margin-bottom: 2.5rem;
        small {
            font-size: 70%;
            margin-top: .5rem;
        }
    }
    h2:not(.js-toggle-exta) {
        @media (max-width: 575px) {
            text-align: center;
        }
    }
    .cart-delivery-extra-item {
        .cart-delivery-info{
            padding-top: 0;
        }
    }
    .g-signin2 > div{
        margin-right: auto;
        @media (max-width: 575px) {
            margin: 0 auto;
        }
    }
    .input-btn {
        @media (max-width: 575px) {
            text-align: center;
        }
    }
}

.js-toggle-exta {
    position: relative;
    cursor: pointer;
    padding-right: 2rem;
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 1.5rem;
        height: 1.5rem;
        transform: translateY(-50%) rotate(90deg);
        transition: transform .3s;
        background-image: url("../../images/icons/arrow-right.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    &.active {
        &::after {
            transform: translateY(-50%) rotate(270deg);
        }
    }
}

.g-signin2{
    width: 100%;
}

.profileSubnav{
    padding: 0;
    list-style: none;
}

#profile {
    @extend #register
}
