.document-section {
    .h5 {
        margin-bottom: 1.2rem;
    }
    ol, ul {
        li {
            margin-bottom: 1rem;
        }
    }
    ol,ul,h1,h2,h3,h4,h5,h6,p,li,div,span,u {
        a {
            color: #0056b3 !important;
            &:hover {
                text-decoration: underline !important;
            }
        }
    }
    a {
        color: #0056b3 !important;
        &:hover {
            text-decoration: underline !important;
        }
    }
}
