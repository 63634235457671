.profile {
    position: relative;
    margin: 0 .5rem;
    svg {
        width: 2rem;
        height: 2rem;
        color: inherit;
    }

}
