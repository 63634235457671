@import "../variables";

.headroom {
    position: fixed;
    width: 100%;
    z-index: 100;
    background: transparent;
    transition: all .3s;
    will-change: transform;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $base-color;
        z-index: 3;
        transform: translateY(-100%);
        transition: all .3s;
    }
    &.active {
        @media (max-width: 991px) {
            background: $base-color;
            &::after {
                height: 100%;
            }
        }
        &::after {
            transform: translateY(0);
        }
        .search-bar {
            opacity: 1;
            transform: translateY(-50%);
            @media (max-width: 991px) {
                transform: translateY(0);
                transition-delay: .3s;
            }
        }

        .js-searchToggle {
            #close-svg {
                opacity: 1;
                transform: scale(1);
                transition: all .3s;
            }

            #open-svg {
                opacity: 0;
                transform: scale(0);
                transition: all .3s;
            }
        }
    }

}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--not-top {
    background: $base-color;
    @include box-shadow(0, 7px, 17px, 0, rgba(0,0,0,0.05));
}
.headroom--unpinned {
    transform: translateY(-150%);
}
