#sidebar {
    width: 22rem;
    h4 {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 2rem;
        @media (max-width: 1199px) {
            font-size: 1.7rem;
        }
        display: inline-block;
        margin-bottom: 0;
        padding-right: .8rem;
        border-right: 1px solid black;
        @media (max-width: 767px) {
            font-size: 1.8rem;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .navigation {
        list-style: none;
        margin: 2rem 0;
        @media (max-width: 1199px) {
            margin: 1.6rem 0;
        }
        padding: 0;

        & > li {
            & > a {
                text-transform: uppercase;
                font-weight: 800;
                font-size: 2.1rem;
                @media (max-width: 1199px) {
                    font-size: 1.9rem;
                }
                @media (max-width: 767px) {
                    font-size: 1.7rem;
                }
                @media (max-width: 575px) {
                    font-size: 1.6rem;
                }
            }
        }

        li {
            position: relative;
            padding-left: 1.5rem;
            @media (max-width: 767px) {
                padding-left: 1.25rem;
            }

            &:not(.category-parent) {
                padding-left: 1.5rem;
                @media (max-width: 767px) {
                    padding-left: 1.25rem;
                }
            }

            & > ul {
                display: none;
                overflow: hidden;
                transition: max-height 0.5s ease-in-out;
            }

            &.active {
                & > .js-category-parent-icon {
                    transform: rotate(90deg);
                }

                & > a {
                    font-weight: 800;
                }
                & > ul {
                    display: block;
                }
            }
        }

        ul {
            li {
                margin: .4rem 0;
                .js-category-parent-icon {
                    top: .3rem;
                    @media (max-width: 1199px) {
                        top: .2rem;
                    }
                }
            }

            a {
                font-size: 1.6rem;
                @media (max-width: 1199px) {
                    font-size: 1.4rem;
                }
                text-transform: lowercase;
                font-weight: 400;
                line-height: 1;
                @media (max-width: 767px) {
                    font-size: 1.4rem;
                }
            }
        }
    }

    @media (max-width: 991px) {
        position: fixed;
        top: 0;
        left: 0;
        background: white;
        height: 100%;
        z-index: 1003;
        width: auto;
        max-width: calc(100% - 4rem);
        padding: 0;
        box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.05);
        transform: translateX(-100%);
        transition: transform .3s;
        &.open {
            transform: translateX(0);
            #sidebar-toggle {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    @media (max-width: 575px) {
        width: 20rem;
    }
    .sidebar-inner {
        width: 100%;
        @media (max-width: 991px) {
            height: 100%;
            padding: 2rem;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            overflow: -moz-hidden-scrollable;
        }
        @media (max-width: 575px) {
            padding: 1.5rem;
        }
    }
}
#sidebar-toggle {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    width: 3rem;
    height: 3rem;
    background: black;
    border-radius: 0 0.8rem 0.8rem 0;
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
        display: flex;
    }
    svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: transform .3s;
    }
}
.js-category-parent-icon {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    transition: transform .3s;
    @media (max-width: 1199px) {
        top: .8rem;
    }
    @media (max-width: 767px) {
        top: .75rem;
        width: 1rem;
        height: 1rem;
    }
    @media (max-width: 575px) {
        top: .6rem;
    }
}

#sidebar-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    z-index: 101;
    &.open {
        transform: translateX(0);
    }
    @media (min-width: 992px) {
        display: none;
    }
}
