#kontakt {
    position: relative;
    z-index: 2;
    max-width: 1640px;
    margin: 0 auto;
    h3 {
        font-size: 2.19rem;
        color: #000000;
        margin-bottom: 2rem;
    }
    .contact-form-wrap {
        height: 100%;
        max-width: 40rem;
        margin: 0 auto;
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
}
