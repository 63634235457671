#about {
    position: relative;
    z-index: 2;
    max-width: 1640px;
    margin: 0 auto;
    h2 {
        text-transform: none;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    h3 {
        font-weight: 900;
        font-size: 1.3rem;
    }
}
