@import '../variables';

/*Alert*/
.alert {
    width: 34rem;
    max-width: calc(100% - 3.5rem);
    height: auto;
    max-height: 200px;
    min-height: 50px;
    position: fixed;
    bottom: 1.75rem;
    right: 1.75rem;
    margin-bottom: 0;
    z-index: 9999;
    font-weight: 400;
    font-size: 1rem;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
    background: $alert-primary-color;
    color: $alert-text-color;
    border-radius: 4px;
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 1.4rem 3.5rem 1.4rem 4.5rem;
    transition: all 1s;
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);

    strong {
        display: block;
    }

    p {
        margin: 0;
        color: lighten($alert-text-color, 30%);
    }

    &.showed {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.alert-icon-wrap {
    position: absolute;
    top: 50%;
    left: 1.3rem;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    padding: 7px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-icon {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.alert-close {
    position: absolute;
    right: 1rem;
    top: 50%;
    width: 1.5rem;
    cursor: pointer;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
}

.alert-close-icon {
    width: 1rem;
    height: 1rem;
    background-image: url("../../images/alerts/close.svg");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

$alert-colors: (
    'danger': #ff3636,
    'success': #5fbc63,
    'info': #17b6e9,
    'warning': #DBA507,
);

@each $alert-box, $color in $alert-colors {
    .alert {
        &.alert-#{$alert-box} {
            border-color: $color;

            .alert-icon-wrap {
                background: $color;
            }

            .alert-icon {
                background-image: url("../../images/alerts/" + $alert-box + ".svg");
            }
        }
    }
}
