.own-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 8%;
    @media (max-width: 1199px) {
        padding: 0 4%;
    }
}
.own-container-fluid {
    width: 100%;
    max-width: 2100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 4%;
}
