.banner-blog-detail {
    background: #f7f5f5;
}
#blog-detail {
    position: relative;
    background: #f7f5f5;
    &:not(.without-img) {
        padding-top: 0;
        .blog-detail-wrapper {
            margin-top: -10rem;
        }
    }
    &.without-img {
        .blog-detail-wrapper {
            margin-top: 4rem;
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: -4rem;
        left: 0;
        display: block;
        width: 100%;
        height: 4rem;
        background: #f7f5f5;
    }
    .birds {
        position: absolute;
        max-width: 50rem;
        &-1 {
            @extend .birds;
            top: 0;
            right: 0;
        }
        &-2 {
            @extend .birds;
            top: 50%;
            left: 0;
            transform: translateY(-60%);
        }
        &-3 {
            @extend .birds;
            bottom: 0;
            right: 0;
        }
    }
}
.blog-detail-wrapper {
    position: relative;
    background: white;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 3rem 3rem 3rem;
    @media (max-width: 991px) {
        max-width: 50rem;
    }
    @media (max-width: 575px) {
        padding: 1rem 2rem 2rem 2rem;
    }
    z-index: 3;
    box-shadow: 0 0 30px 14px rgba(103, 103, 103, 0.2);
    #page-breadcrumbs {
        margin-bottom: 5rem;
        @media (max-width: 991px) {
            margin-bottom: 2rem;
        }
    }
    .blog-tag {
        margin-bottom: 2rem;
    }
    .blog-title {
        margin-bottom: 1rem;
        h1 {
            font-size: 3rem;
            font-weight: 700;
            max-width: 50rem;
            @media (max-width: 575px) {
                font-size: 2rem;
            }
            @media (max-width: 425px) {
                font-size: 1.5rem;
            }
        }
    }
    .blog-perex {
        margin: 2rem 0 4rem 0;
    }
    .blog-content {
        img {
            margin: 1rem 0 2rem 0;
            max-width: 100%;
            height: auto;
        }
        h2 {
            font-size: 1.6rem;
            font-weight: 800;
            text-transform: none;
        }
        h3 {
            font-size: 1.3rem;
            font-weight: 800;
            text-transform: none;
        }
        h4 {
            font-size: 1.1rem;
            font-weight: 800;
            text-transform: none;
        }
    }
}
