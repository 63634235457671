.pagination {
    transition: all 0.3s;
    display: inline-flex;

    .page-item {
        .page-link {
            color: #000000;
            padding: 0.8em 1.0em;
            transition: all .3s;
        }

        &.active {
            .page-link {
                color: #ffffff;
                background: #000000;
                border-color: #000000;
            }
        }

        &:hover {
            .page-link {
                color: #000000;
                background: #ffcf37;
                border-color: #ffcf37;
            }
        }
        &.disabled {
            cursor: auto;
            opacity: .5;
            &:hover {
                .page-link {
                    color: #000000;
                    background: transparent;
                    border-color: #dee2e6;
                }
            }
        }
    }
}
