.js-addToCart {
    &.active {
        .add-to-cart-btn-text {
            opacity: 0;
        }
        .add-to-cart-btn-loader {
            opacity: 1;
        }
    }
    &.added-to-cart {
        .add-to-cart-btn-text {
            opacity: 1;
        }
        .add-to-cart-btn-loader {
            opacity: 0;
        }
        .add-to-cart-btn-text-after {
            transform: translate(-50%, -100%);
            opacity: 1;
            visibility: visible;
            pointer-events: none;
        }
    }
}
.add-to-cart-btn-text {
    transition: opacity .3s;
}
.add-to-cart-btn-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1.5rem;
    height: 1.5rem;
    max-width: 100%;
    max-height: 100%;
    background-image: url("../../images/icons/loader.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity .3s;
    opacity: 0;
}
.add-to-cart-btn-text-after {
    position: absolute;
    top: -.5rem;
    left: 50%;
    background: white;
    font-size: .8rem;
    letter-spacing: 0;
    font-weight: 500;
    white-space: normal;
    width: 10rem;
    color: black;
    padding: .5rem;
    transform: translate(-50%, -150%);
    border-radius: .5rem;
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all .3s;
}
