.scroll-down {
    position: absolute;
    bottom: 0;
    left: 50%;
    background: $base-color;
    padding: .5rem;
    border-radius: 4rem 4rem 0 0;
    min-width: 13rem;
    text-align: center;
    transform: translate(-50%,100%);
    opacity: 0;
    transition: opacity .3s, transform .7s;

    .icon-scroll {
        width: 28px;
        height: 45px;
        box-shadow: inset 0 0 0 3px #000;
        border-radius: 25px;
        margin: 0 auto;

        &::before {
            display: block;
            content: '';
            width: 3px;
            height: 10px;
            background: #000;
            margin: 0 auto;
            position: relative;
            top: 8px;
            animation-duration: 1.5s;
            animation-iteration-count: infinite;
            animation-name: scroll;
        }
    }
    &.active {
        transform: translate(-50%,0%);
        opacity: 1;
    }
}
@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(22px);
    }
}
