@import '../variables';
@import '../mixins/mixins';

.eshop,
.eshop-home {
    #nav-icon {
        right: auto;
        left: 3rem;
    }

    #burger-menu {
        @include align-items(flex-start);
        .burger-menu-inner {
            padding: 4rem 0;
        }
    }

    #desktop-menu {
        & > li {
            a {
                font-size: 1.77rem;
                letter-spacing: 5px;
            }
        }

        .menu-dropdown {
            li {

                a {
                    font-size: 1rem;
                    color: inherit;
                    letter-spacing: 1px;
                }
                &:hover {
                    a {
                        color: inherit;
                        opacity: .7;
                    }
                }
            }
        }
    }
    .menu-dropdown {
        color: $base-color;
        background: rgba(#929292, .89);
    }
}
.divider{
    width: 100%;
    border-bottom: 1px solid black;
    display: block;
    opacity: 0.1;
    margin: 10px 0;
}
