@import '../variables';

.subcategories-wrap {
    background-color: #f8f8f8;
}
.subcategories {
    text-align: center;
    a {
        display: inline-block;
        @media (max-width: 575px) {
            display: block;
            padding: 1.5rem 1rem;
        }
        font-weight: 600;
        padding: 1.25rem 1.5rem;
        text-align: center;
        color: #000000;
        transition: background-color .3s;
        &.active {
            font-weight: 800;
            background: #000000;
            color: #ffffff;
        }
        &:not(.active):hover {
            background: rgba(#000000, .6);
            color: #ffffff;
        }
    }
    h2 {
        font-size: 1.2rem;
        font-weight: inherit;
        line-height: 1.2;
        text-transform: uppercase;
        margin: 0;
        color: inherit;
        @media (max-width: 575px) {
            font-size: 1.1rem;
        }
    }
}
