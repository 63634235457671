.heading-with-icon {
    margin-bottom: 1rem;
    @include flexbox();
    @include align-items(center);
    @media (max-width: 575px) {
        flex-direction: column;
        text-align: center;
    }

    .icon-wrap {
        margin-left: 2rem;
        flex-shrink: 0;
        @media (max-width: 575px) {
            order: 1;
            margin-left: 0;
            margin-bottom: 1rem;
        }
    }

    h2 {
        text-transform: lowercase;
        font-size: 4.5rem;
        font-weight: 800;
        margin-bottom: 0;
        border-right: 5px solid black;
        padding-right: 2rem;

        span {
            display: block;
            line-height: 1;
            font-size: 35%;
            letter-spacing: 6px;
        }

        @media (max-width: 575px) {
            order: 2;
            border: none;
            padding-right: 0;
            font-size: 3.5rem;
        }
    }

    img {
        height: 7rem;
        width: 7rem;
        @media (max-width: 575px) {
            height: 6rem;
            width: 6rem;
        }
    }

    p {
        max-width: 24rem;
    }

    &--sm {
        @extend .heading-with-icon;

        h2 {
            font-size: 3.2rem;
            font-weight: 500;
            border-right: 3px solid black;
            @media (max-width: 575px) {
                font-size: 2.5rem;
                border: none;
            }

            strong {
                font-weight: 900;
            }
        }

        img {
            width: 4rem;
            height: 4rem;
        }

        @media (max-width: 1199px) {
            justify-content: center;
        }
    }

    &--xs {
        @extend .heading-with-icon;
        justify-content: center;

        h4 {
            text-transform: lowercase;
            font-size: 1.7rem;
            font-weight: 700;
            border-right: 3px solid black;
            padding-right: 1rem;
            @media (max-width: 575px) {
                font-size: 2.5rem;
                border: none;
                padding-right: 0;
                order: 2;
            }
            margin-bottom: 0;
        }
        .icon-wrap {
            margin-left: 1rem;
            @media (max-width: 575px) {
                margin-left: 0;
            }
        }
        img {
            width: 2.8rem;
            height: 2.8rem;
        }
    }
}
