@import '../variables';
@import '../mixins/transform';
@import '../mixins/transition';

$cookies-bar-bg: #ffffff;
$cookies-bar-text: #000000;

#cookies-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    background-color: $cookies-bar-bg;
    color: $cookies-bar-text;
    font-size: .9rem;
    padding: 1rem 2rem;
    z-index: 99999;
    @include transition(transform .5s ease-out);
    @media (max-width: 767px) {
        @include flex-direction(column);
        text-align: center;
    }

    &.active {
        @include transform(translateY(100%));
    }

    .cookies-nav {
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        white-space: nowrap;
        @media (max-width: 767px) {
            margin-top: 1.5rem;
        }
    }

    .cookies-btn {
        display: inline-block;
        position: relative;
        color: $cookies-bar-bg;
        text-align: center;
        font-weight: 600;
        font-family: $base-font-family;
        text-transform: uppercase;
        vertical-align: middle;
        cursor: pointer;
        border: none;
        box-shadow: none;
        border-radius: .8rem;
        background: $cookies-bar-text;
        padding: .4rem 1.4rem;
        margin: 0 1.5rem;
        font-size: .9em;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }

    @media (max-width: 991px) {
      font-size: 1.2rem;
    }
    @media (max-width: 767px) {
        font-size: 1.3rem;
    }
    @media (max-width: 575px) {
        font-size: 1.4rem;
    }
}
