@import '../variables';
@import '../mixins/transition';
@import '../mixins/transform';
@import '../mixins/box-shadow';

.search {
    //position: relative;
    margin: 0 .5rem;

    svg {
        width: 2rem;
        height: 2rem;
        @media (max-width: 991px) {
            width: 1.5rem;
            height: 1.5rem;
        }
        color: inherit;
    }
}

.js-searchToggle {
    position: relative;
    z-index: 5;
    cursor: pointer;

    #close-svg {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        transition: all .3s;
    }

    #open-svg {
        opacity: 1;
        transform: scale(1);
        transform-origin: center;
        transition: all .3s;
    }
}

.search-bar {
    position: absolute;
    margin: 0;
    top: 50%;
    right: 0;
    z-index: 4;
    //opacity: 0;
    width: 100%;
    padding: 1rem;
    text-align: center;
    transform: translateY(-200%);
    transition: all .3s;

    input {
        width: 40rem;
        font-size: 1.3rem;
        outline: none;
        outline-offset: 0;
        border: 1px solid #f0f0f0;
        border-radius: .4rem;
        padding: .3rem 1rem;
        margin: 0;
        height: inherit;
        vertical-align: middle;
        @include transition(box-shadow .3s);
        @include box-shadow(0, 0, 7px, 2px, rgba(103, 103, 103, .18));

        &:focus {
            @include box-shadow(0, 0, 7px, 3px, rgba(103, 103, 103, .25));
        }
    }
    @media (max-width: 991px) {
        max-width: 100%;
        top: 0;
        left: 0;
        right: 0;
        input {
            max-width: 100%;
        }
    }
}
