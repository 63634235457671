@import '../variables';
@import '../mixins/transform';

// Swiper
@import "~swiper/dist/css/swiper.min.css";

.category-glasses {
    position: relative;
    background: url("../../images/okuliare-bg.jpg") no-repeat center top;
    background-size: cover;
    &::after {
        content: '';
        position: absolute;
        bottom: -.5rem;
        left: 0;
        right: 0;
        height: 22rem;
        background-image: url("../../images/banner-overlay.png");
        z-index: 2;
        @media (max-width: 767px) {
            background-position: right;
            height: 20rem;
        }
    }
}
.category-product-ordering {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -.5rem;
    .ordering {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        @media (max-width: 767px) {
            width: 100%;
            justify-content: center;
            margin: 1rem 0;
        }
        img {
            width: .8rem;
            height: .8rem;
        }
        & > div {
            text-transform: lowercase;
            font-size: 1.1rem;
            font-weight: 700;
            letter-spacing: 1px;
            margin: .5rem;
            cursor: pointer;
            @media (max-width: 425px) {
                width: 100%;
            }
            img {
                transform: rotate(-90deg);
            }
            &.active {
                font-weight: 900;
                img {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

.category-carousel {
    margin: 3rem 0;
    @media (max-width: 1199px) {
        margin: 2rem 0;
    }
}
.category-carousel-item {
    position: relative;
    padding: 1rem;
}

.category-carousel-img {
    text-align: center;
    //Aspect ratio of product images
    position: relative;
    width: 100%;
    padding-top: 60%;
    img {
        //Aspect ratio of product images
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        padding: 1.5rem;
        text-align: center;
        object-fit: contain;
        z-index: 1;
    }
}

.category-carousel-title {
    text-align: center;
    margin-top: 1rem;
    text-transform: lowercase;
    color: $text-color;
    h3 {
        font-weight: 900;
        font-size: 1.5rem;
    }
}
