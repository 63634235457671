@import '../variables';
@import '../mixins/mixins';
@import '../mixins/placeholder';
@import "../elements/product";
@import "../elements/cart-steps";

.text-stroke {
    text-decoration: line-through;
}
#cart {
    max-width: 100rem;
    margin: auto;
    padding-top: 9rem;
    @media (max-width: 991px) {
        padding-top: 7rem;
    }
}

#cart-product-list {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 0 2rem;
}

.cart-product {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.2rem 0;
    color: $primary-color;
    margin-bottom: .5rem;
    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -2rem;
        right: 0;
        width: calc(100% + 4rem);
        height: 100%;
        border-bottom: 1px solid darken($base-color, 15%);
        z-index: -1;
    }

    &.no-image {
        .cart-product-img {
            height: 5rem;
        }
        .cart-product-title {
            h3 {
                font-size: 1rem;
            }
        }
        .cart-product-price-total {
            font-size: 1.1rem;
        }
    }

    @media (max-width: 1199px) {
        width: 100%;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        .cart-product-price-total {
            margin-bottom: 1rem;
        }
        &.no-image {
            .cart-product-img {
               display: none;
            }
            .cart-product-quantity {
                display: none;
            }
        }
    }
}

.cart-product-img {
    width: 9rem;
    height: 9rem;
    background: white;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.cart-product-title {
    margin-right: auto;
    margin-left: 1.7rem;

    h3 {
        font-size: 1.1rem;
        font-weight: 900;
        line-height: 1;
        color: inherit;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        margin: 1rem auto;
        text-align: center;
    }
}

.cart-product-quantity {
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    min-width: 8rem;
    justify-content: flex-end;
    @media (max-width: 767px) {
        justify-content: center;
        margin: .5rem 0 1rem 0;
    }

    input {
        font-weight: inherit;
        text-align: center;
        border: 1px solid lighten($text-color, 70%);
        border-left: none;
        border-right: none;
        border-radius: 0;
        max-width: 3rem;
        padding: .5rem;
        box-shadow: none;
        background: $base-color;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

}

.cart-product-quantity-nav {
    display: flex;
    justify-content: center;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: inherit;
        text-align: center;
        border: 1px solid lighten($text-color, 70%);
        border-radius: 0;
        width: 2rem;
        padding: 0;
        background: white;
        box-shadow: none;

        &:focus {
            outline: none;
            border: 1px solid lighten($text-color, 70%);
            box-shadow: none;
        }

        img {
            width: 1.1rem;
            height: 1.1rem;
        }
        &:first-child {
            img {
                transform: rotate(-45deg);
                width: .7rem;
            }
        }
        &:last-child {
            img {
                transform: rotate(45deg);
                width: .7rem;
            }
        }
    }

    @media (max-width: 991px) {
        button {

        }
    }
    @media (max-width: 767px) {
        flex-direction: row;
        button {
        }
    }
}

.cart-product-price {
    font-size: 1.1rem;
    font-weight: 500;
    min-width: 6rem;
    margin-left: 1rem;
    white-space: nowrap;
    text-align: right;
    @media (max-width: 767px) {
        margin-left: 0;
        width: 100%;
        text-align: center;
    }
    &-total {
        @extend .cart-product-price;
        font-size: 1.3rem;
        font-weight: 600;
    }
}

.cart-product-remove {
    cursor: pointer;
    margin-left: 3rem;
    border-radius: 50%;
    overflow: hidden;
    min-width: 2rem;

    img {
        border-radius: 50%;
        padding: .5rem;
        width: 2rem;
        height: 2rem;
        transition: all .3s;
    }

    &:hover {
        img {
            background: #e0e0e0;
            transform: rotate(180deg);
        }
    }

    @media (max-width: 767px) {
        position: absolute;
        top: 1rem;
        right: -1rem;
    }
}

.cart-voucher {
    font-size: 1.1rem;
    color: black;
    font-weight: 800;
    text-align: right;
    width: 100%;
    margin-top: 4rem;
    line-height: 1;

    .cart-voucher-input-group {
        display: flex;
        justify-content: center;
        max-width: 25rem;

        .form-control {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        .cart-voucher-send {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: inherit;
            text-align: center;
            border: none;
            border-top-right-radius: 0.4rem;
            border-bottom-right-radius: 0.4rem;
            padding: 0.5rem 1rem;
            box-shadow: none;
            letter-spacing: 0;
            background: #000000;
            color: #ffffff;
        }
    }

}

.cart-total {
    font-size: 1.5rem;
    color: black;
    font-weight: 800;
    text-align: right;
    width: 100%;
    margin-top: 4rem;
    line-height: 1;
    span {
        font-size: 1.7rem;
        font-weight: 900;
        letter-spacing: 0;
        white-space: nowrap;
    }
    .cart-total-notax {
        font-size: 1.2rem;
        opacity: .8;
        margin-bottom: .5rem;
        span {
            font-size: 1.4rem;
            display: inline-block;
            min-width: 150px;
        }
    }
    .cart-total-hero {
        span {
            display: inline-block;
            min-width: 150px;
        }
    }
    @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        text-align: center;
        margin-bottom: 1rem;
        .cart-total-notax, .cart-total-hero {
            span {
                display: block;
                margin-top: 10px;
            }
        }
        .cart-total-notax {margin-bottom: 15px;}
    }
}

.cart-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        flex-direction: column;
        justify-content: center;
        div {
            margin: .5rem 0;
            &:nth-child(1) {
                order: 2;
            }
        }
    }
    .own-btn {
        &:hover {
            background-color: $tertiary-color;
            color: $base-color;
        }
    }
}

.grey-box {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100%;
        background: #f6f8fa;
        z-index: -1;
        @media (max-width: 991px) {
            right: -5vw;
            width: 110vw;
        }
    }
}

//Payment
#cart-payment {
    h2 {
        font-size: 1.6rem;
        font-weight: 900;
        text-transform: none;
        letter-spacing: 1px;
        line-height: 1;
        color: $primary-color;
        margin-bottom: 2rem;
        @media (max-width: 575px) {
            text-align: center;
        }
    }
    .form-check {
        label {
            padding-left: 1.5rem;
            cursor: pointer;
        }
        input[type=radio],
        input[type=checkbox] {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            opacity: 0;
            cursor: pointer;
            &:checked ~ .check {
                background: $secondary-color;
                border-color: $secondary-color;
            }
            &:checked ~ label span {
                opacity: 1;
            }
        }
        .check {
            position: absolute;
            top: 50%;
            left: 0;
            width: 1.5rem;
            height: 1.5rem;
            background: #ffffff;
            border: 1px solid lighten($text-color, 70%);
            border-radius: .4rem;
            transition: background-color .3s;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }
    .form-check-value {
        text-transform: uppercase;
        font-weight: 800;
        white-space: nowrap;
        margin-left: 1rem;
        &-free {
            @extend .form-check-value;
            color: $secondary-color;
        }
    }
    .cart-payment-item {
        &:nth-child(2) {
            margin-top: 2rem;
        }
    }
    .profile-card {
        .card-title {
            line-height: 1;
            font-size: 1rem;
        }
    }
}
#cart-summary-wrap {
    @include flexbox();
    @include flex-direction(column);
    width: 100%;
    padding: 2rem;
    background: #f9f9f9;
}
.cart-summary {
    @include flexbox();
    @include align-items(center);
    justify-content: space-between;
    margin-bottom: 1rem;
    @media (max-width: 575px) {
        margin-bottom: .5rem;
    }
}
.cart-summary-title {
    h3 {
        text-transform: none;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.2;
        color: $primary-color;
        margin-bottom: 0;
        span {
            display: block;
            font-weight: 800;
        }
    }
}
.cart-summary-price {
    width: 9rem;
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    white-space: nowrap;
}
.cart-summary-total {
    margin-bottom: 0;
    .cart-summary-price {
        font-size: 1.7rem;
        font-weight: 900;
        width: auto;
        margin-left: auto;
    }
}

.cart-delivery-info {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    .cart-delivery-info-item-col {
        width: calc(50% - 1rem);
        @media (max-width: 575px) {
            width: 25rem;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        &-w100 {
            @extend .cart-delivery-info-item-col;
            width: 100%;
            @media (max-width: 575px) {
                width: 25rem;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .cart-delivery-info-item {
        width: 100%;
        margin-bottom: 2rem;
    }
    .form-control {
        //border-radius: 3rem;
        //padding: 1.3rem 2.3rem;
        border-color: lighten($text-color, 70%);
        //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

        @include placeholder {
            color: $text-color;
            text-transform: none;
            font-weight: 600;
            opacity: .4 !important;
            letter-spacing: 1px;
        }
    }
    .sr-only {
        position: absolute;
    }
}

.cart-delivery-extra-item {
    display: none;
    overflow: hidden;
    transition: all .3s;
    .cart-delivery-info {
        padding-top: 1.5rem;
    }
}

//Thankyou
#cart-thankyou {
    text-align: center;
    margin-top: 8rem;
    .cart-thankyou-text {
        font-size: 1.1rem;
        font-weight: 500;
    }
    .cart-nav {
        justify-content: center;
    }
}

