.banner-floated {
    position: absolute;
    top: 50%;
    right: 0;
    max-width: 18rem;
    background: #ffcf37;
    padding: 3.5rem 1rem 1.7rem 2.5rem;
    border-radius: 3rem 0 0 3rem;
    transform: translate(100%, -50%);
    transition: transform .5s;
    @media (max-width: 991px) {
        position: relative;
        max-width: 100%;
        width: 50rem;
        top: auto;
        right: auto;
        margin: auto auto 0 auto;
        border-radius: 2rem;
        padding: 1.5rem;
        transform: translate(150%, 0%);
    }
    @media (max-width: 575px) {
        text-align: center;
    }
    &.active {
        transform: translate(0, -50%);
        @media (max-width: 991px) {
            transform: translate(0%, 0%);
        }
    }
    h3 {
        font-weight: 900;
        font-size: 3rem;
        @media (max-width: 991px) {
            font-size: 2.5rem;
        }
        small {
            display: block;
            font-size: 45%;
            font-weight: 600;
        }
    }
    p {
        font-size: 1.2rem;
        font-weight: 500;
        @media (max-width: 991px) {
            font-size: 1rem;
        }
    }
    .own-btn {
        background-color: #3f535f;
        border-radius: 3rem;
        &:hover {
            background-color: #ffffff;
        }
    }
    img {
        position: absolute;
        top: 1.3rem;
        left: 1.7rem;
        width: 2rem;
        height: 2rem;
        @media (max-width: 991px) {
            display: none;
        }
    }
}
