@import '../variables';
@import '../mixins/mixins';

/*Checkbox*/
.checkbox {
    position: relative;
    cursor: pointer;

    .checkbox-label {
        position: relative;
        display: inline-block;
        padding-left: 2rem;
        letter-spacing: 1px;
        font-size: .9rem;
        text-align: left;

        &:before {
            content: " ";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 1.5rem;
            width: 1.5rem;
            opacity: 0;
            background-image: url("../../images/icons/check.svg");
            background-position: center;
            background-size: 75%;
            background-repeat: no-repeat;
            @include transition(opacity .3s);
            @include transform(translateY(-50%));
            z-index: 1;
        }

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            height: 1.5rem;
            width: 1.5rem;
            background: #ffffff;
            border: 1px solid #b3b3b3;
            border-radius: 0.4rem;
            @include transform(translateY(-50%));

        }
    }

    input {
        position: absolute;
        top: 50%;
        height: 1.5rem;
        width: 1.5rem;
        opacity: 0;
        @include transform(translateY(-50%));
        @include transition(all .3s);
    }

    input:checked ~ .checkbox-label:before {
        opacity: 1;
    }
}
