.blog-main {
    position: relative;
    background: url("../../images/blog-bg.jpg") no-repeat center top;
    background-size: cover;
    &::after {
        content: '';
        position: absolute;
        bottom: -.5rem;
        left: 0;
        right: 0;
        height: 23rem;
        background-image: url("../../images/banner-overlay.png");
        z-index: 2;
        @media (max-width: 767px) {
            background-position: right;
            height: 20rem;
        }
    }
}

.blog-grid-home {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    margin: 0 -1rem;
    transform-style: preserve-3d;

    .blog-grid-item {
        position: relative;
        margin: 2.5rem;
        width: calc(33.33% - 5rem);
        transform-style: preserve-3d;
        transition: transform .3s;
        @include box-shadow(0, 0, 20px, 6px, rgba(103, 103, 103, 0.3));
        @media (max-width: 1199px) {
            margin: 1rem;
            width: calc(33.33% - 2rem);
        }
        @media (max-width: 991px) {
            width: calc(50% - 2rem);
        }
        @media (max-width: 575px) {
            width: calc(100% - 2rem);
        }

        .blog-title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 97%, rgba(0, 0, 0, 1) 99%);
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 97%, rgba(0, 0, 0, 1) 99%);
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 97%, rgba(0, 0, 0, 1) 99%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
        }

        h3 {
            text-transform: uppercase;
            font-size: calc(1.1rem + 1vw);
            @media (max-width: 575px) {
                font-size: calc(1.1rem + 3vw);
            }
            text-align: center;
            font-weight: 800;
            letter-spacing: 1px;
            color: $base-color;
            padding: 3rem 1rem 1rem 1rem;
            margin-bottom: 0;
        }

        &:hover {
            transform: translateY(1rem) scale(1.1);

            @media (max-width: 991px) {
                transform: none;
            }
        }

    }

    .blog-img {
        text-align: center;
        //Aspect ratio of product images
        position: relative;
        width: 100%;
        padding-top: 120%;
        @media (max-width: 575px) {
            padding-top: 80%;
        }

        img {
            //Aspect ratio of product images
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            object-fit: cover;
            z-index: 1;
        }
    }
}

.blog-grid {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(flex-start);
    margin: 0 auto;

    &.news {
        .blog-grid-item {
            margin: 3%;
            width: calc(33.33% - 6%);
            transition: all .3s;
            @media (max-width: 1199px) {
                margin: 1rem;
                width: calc(33.33% - 2rem);
            }
            @media (max-width: 991px) {
                width: calc(50% - 2rem);
            }
            @media (max-width: 575px) {
                width: calc(100% - 2rem);
            }
        }
    }

    .blog-grid-item {
        position: relative;
        margin: 3%;
        width: calc(50% - 7%);
        transition: all .3s;
        @media (max-width: 1199px) {
            margin: 1rem;
            width: calc(50% - 2rem);
        }
        @media (max-width: 991px) {
            width: calc(50% - 2rem);
        }
        @media (max-width: 575px) {
            width: calc(100% - 2rem);
        }

        &:hover {
            .overlay {
                opacity: 1;
            }
        }
    }


    .blog-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }


    .blog-title {
        margin: 1.4rem 0;

        h3 {
            display: inline-block;
            font-size: 1.6rem;
            font-weight: 800;
            color: $text-color;
            margin-bottom: 0;
            max-width: 25rem;
        }
    }

    .blog-img {
        text-align: center;
        //Aspect ratio of product images
        position: relative;
        width: 100%;
        padding-top: 70%;
        &::after {
            content: '';
            position: absolute;
            bottom: -.5rem;
            left: 0;
            right: 0;
            height: 2rem;
            background-image: url("../../images/blog/blog-overlay.png");
            z-index: 2;
        }

        img {
            //Aspect ratio of product images
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            object-fit: cover;
            object-position: center;
            z-index: 1;
        }

        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            z-index: 1;
            background: rgba($text-color, .5);
            transition: opacity .3s;
        }

        .read-more {
            display: block;
            color: $base-color;
            padding: .35rem 1.6rem;
            border: 1px solid $base-color;
            transition: all .3s;

            &:hover {
                background: $base-color;
                color: $text-color;
            }
        }
    }

    .blog-more {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            font-weight: 600;
            font-size: .8rem;
        }
    }

    .blog-views {
        display: flex;
        align-items: center;

        img {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: .5rem;
        }
    }

    .own-btn {
        border-radius: .5rem;
        padding: .4rem 1rem;
        font-size: 1rem;
        box-shadow: none;
        &:hover {
            background: lighten($primary-color, 10%);
            color: $base-color;
        }
    }
    .blog-header {
        padding: 1.5rem 0;
    }
    .blog-footer {
        margin-top: auto;
    }

    &.with-padding {
        .blog-grid-item {
            @include box-shadow(0, 13px, 17px, -6px, rgba(103, 103, 103, 0.15));
            &:hover {
                @include box-shadow(0, 13px, 17px, -6px, rgba(103, 103, 103, 0.3));
            }
        }
        .blog-header {
            padding: 2rem;
        }

        .blog-footer {
            padding: 0 2rem 2rem 2rem;
        }
    }
}
.blog-tag {
    font-size: .9rem;
}
.blog-date {
    color: lighten($text-color, 60%);
}
.blog-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .8rem;
    margin-bottom: 1rem;
    color: lighten($text-color, 40%);
}

.blog-author {
    img {
        width: 2rem;
        height: 2rem;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 .5rem;
    }
}

.blog-articles-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    .blog-content {
        width: calc(100% - 27%);
        @media (max-width: 1366px) {
            width: calc(100% - 22rem);
        }
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    .blog-sidebar {
        width: 25%;
        margin-top: 3rem;
        @media (max-width: 1366px) {
            width: 20rem;
        }
        @media (max-width: 991px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 5rem;
            & > div {
                width: 30%;
            }
        }
        @media (max-width: 767px) {
            margin-top: 2rem;
            flex-direction: column;
            & > div {
                width: 100%;
                margin-bottom: 3rem;
            }
        }
    }
}
.blog-sidebar {
    & > div {
        margin-bottom: 6rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.instagram-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    & > div {
        margin: 0 .5rem;
        text-align: center;
    }
    span {
        font-weight: bold;
    }
}
.instagram-posts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;
}
.instagram-item {
    width: calc(50% - 1rem);
    margin: .5rem;
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        z-index: 1;
        background: rgba($text-color, .5);
        transition: opacity .3s;
    }

    .show {
        display: block;
        color: $base-color;
        font-size: .85rem;
        padding: .3rem 1.2rem;
        border: 1px solid $base-color;
        transition: all .3s;

        &:hover {
            background: $base-color;
            color: $text-color;
        }
    }
    &:hover {
        .overlay {
            opacity: 1;
        }
    }
}
.instagram-img {
    text-align: center;
    //Aspect ratio of product images
    position: relative;
    width: 100%;
    padding-top: 100%;
    img {
        //Aspect ratio of product images
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        object-fit: cover;
        z-index: 1;
    }
}

.follow-counters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    max-width: 20rem;
    margin: 0 auto;
}
.follow-item {
    width: calc(33.33% - 1rem);
    margin: 0 .5rem;
    img {
        width: 3rem;
        height: 3rem;
        margin-bottom: 1rem;
    }
    span {
        display: block;
        line-height: 1;
    }
    .follow-item-count {
        font-weight: bold;
        font-size: 1.1rem;
    }
    @media (max-width: 991px) and (min-width: 768px) {
        font-size: .8rem;
    }
}
.favorite-counter {
    @media (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
    }
    @media (max-width: 575px) {
        flex-direction: column;
    }
}
.favorite-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: 767px) {
        width: calc(50% - 2rem);
        margin: 1rem;
    }
    @media (max-width: 575px) {
        width: calc(100% - 2rem);
    }
}
.favorite-title {
    width: 75%;
    max-width: 25rem;
    margin-right: 2rem;
    h5 {
        display: inline-block;
        font-weight: 900;
        font-size: 1.1rem;
        margin-bottom: 0;
    }
}
.favorite-date {
    font-size: .7rem;
    text-align: right;
    display: block;
}
.favorite-img {
    position: relative;
    width: 25%;
    max-width: 7rem;
    flex-shrink: 0;
    .img-wrap {
        text-align: center;
        position: relative;
        width: 100%;
        padding-top: calc(100% - 4px);
        border-radius: 50%;
        border: 2px solid #c2c2c2;
        overflow: hidden;
        img {
            //Aspect ratio of product images
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 3px solid white;
            text-align: center;
            object-fit: cover;
            z-index: 1;
        }
    }
    .favorite-position {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 2rem;
        background: black;
        color: white;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        line-height: 2.8rem;
        font-weight: 800;
        text-align: center;
        z-index: 1;
    }
}
