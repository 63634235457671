@import '../variables';

/*Footer*/
footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 50;
    background: #000000;
    color: $base-color;
    overflow: hidden;

    a {
        color: $base-color;

        &:hover {
            color: lighten($base-color, 15%);
        }
    }
    ul {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin: 0 0 0 -1rem;
        padding: 0;
        li {
            margin-right: 1rem;
        }
        @media (max-width: 1199px) {
            justify-content: center;
            margin: 0 0 1rem 0;
        }
        @media (max-width: 767px) {
            flex-direction: column;
            li {
                margin-right: 0;
            }
        }
    }
    .row {
        position: relative;
        &:nth-child(2) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: 40rem;
                height: 1px;
                background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
                background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
                background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
                transform: translateX(-50%);
            }
        }
    }
    img {
        width: 3rem;
        height: 3rem;
        margin: 0 .5rem;
    }
}
