@import '../variables';
@import '../mixins/transform';

.home-1 {
    background: url("../../images/home-1.jpg") no-repeat 15% top;
    background-size: cover;
    @media (max-width: 991px) {
        .banner-content {
            padding: 5rem 0;
        }
    }
    .content {
        max-width: 30rem;
        margin-top: 5rem;
        margin-left: 5rem;
        @media (max-width: 991px) {
            margin-top: 3rem;
            margin-left: 3rem;
            margin-bottom: 1rem;
        }
        @media (max-width: 575px) {
            text-align: center;
            margin-left: 0;
        }
    }
    h1 {
        font-weight: 900;
        text-transform: uppercase;
        font-size: 6rem;
        line-height: 1;
        margin-left: -3px;
        span {
            display: block;
            font-size: 2.5rem;
            letter-spacing: 12px;
            line-height: 1;
            margin-left: 3px;
        }
    }
}
.home-2 {
    .content {
        max-width: 30rem;
        margin-top: 6rem;
        @media (max-width: 575px) {
            margin-top: 3rem;
            text-align: center;
        }
    }
    .img-bg-wrap {
        img {
            object-position: left bottom;
        }
    }
    @media (max-width: 767px) {
        min-height: auto;
    }
}
.img-floated {
    position: absolute;
    top: 26%;
    left: 0;
    width: 45rem;
    max-width: 100%;
    z-index: -1;
    @media (max-width: 767px) {
        display: none;
    }
}
.home-3 {
    .content {
        max-width: 30rem;
        margin-top: 6rem;
        @media (max-width: 575px) {
            margin-top: 0;
            text-align: center;
        }
    }
    @media (max-width: 767px) {
        min-height: auto;
        margin-bottom: 6rem;
    }
    @media (max-width: 575px) {
        margin-bottom: 0;
    }

}

.img-bg-wrap {
    position: absolute;
    top: 0;
    left: 30rem;
    width: calc(100% - 30rem);
    height: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: left top;
    }
    @media (max-width: 767px) {
        display: none;
    }
}

#blog-home {
    padding-top: 0;
}
