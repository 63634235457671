.category-carousel {
    margin: 3rem 0;
}
//.swiper-container {
//}
.swiper-button {
    width: 3rem;
    height: 3rem;
    padding: .5rem;
    border-radius: 50%;
    svg {
        width: 1.7rem;
        height: 1.7rem;
    }
    &:focus {
        outline: none;
    }
    @media (max-width: 991px) {
        display: none;
    }
}
.swiper-button-prev {
    background: transparent;
    svg {
        margin-left: .05rem;
        margin-top: .15rem;
        transform-origin: center;
        transform: rotate(180deg);
    }
}
.swiper-button-next {
    background: transparent;
    svg {
        margin-left: .3rem;
        margin-top: .1rem;
    }
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}

