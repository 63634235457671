#page-breadcrumbs {
    margin-bottom: 2rem;
    ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        margin: 0 -.5rem;
        li {
            position: relative;
            margin: 0 .5rem;
            &::after {
                content: '';
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                background-image: url("../../images/icons/right.svg");
                background-size: contain;
                background-repeat: no-repeat;
                margin-left: .9rem;
                vertical-align: middle;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
        img {
            width: 1.5rem;
            height: 1.5rem;
        }
        @media (max-width: 575px) {
            flex-wrap: wrap;
        }
    }
    @media(max-width: 991px) {
        margin-top: 1rem;
        margin-bottom: 3rem;
    }
}
