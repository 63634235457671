.offset {
    padding: $section-padding-md;
    @media (max-width: 991px) {
        padding: $section-padding-sm;
    }
}

.strikethrough {
    position: relative;
}

.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: #ff0b0b;

    -webkit-transform:rotate(-5deg);
    -moz-transform:rotate(-5deg);
    -ms-transform:rotate(-5deg);
    -o-transform:rotate(-5deg);
    transform:rotate(-5deg);
}


.font-size-90 {
    font-size: 90%;
}

.font-size-80 {
    font-size: 80%;
}
