@-webkit-keyframes fadeInAlert {
  0% {
	opacity: 0;
	-webkit-transform: translateY(200%);
	transform: translateY(200%);
  }
  20%,
  80% {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
  }
  100% {
	opacity: 0;
	-webkit-transform: translateY(200%);
	transform: translateY(200%);
  }
}
@keyframes fadeInAlert {
  0% {
	opacity: 0;
	-webkit-transform: translateY(200%);
	transform: translateY(200%);
  }
  20%,
  80% {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
  }
  100% {
	opacity: 0;
	-webkit-transform: translateY(200%);
	transform: translateY(200%);
  }
}
