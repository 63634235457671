$badge-primary-bg: #ff0000;

.product-grid {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    margin: 0 -.5rem;
    .product-grid-item {
        background: white;
        margin: .5rem;
        border-radius: 1.5rem;
        transition: box-shadow .3s;
        width: calc(33.33% - 1rem);
        @media (max-width: 767px) {
            width: calc(50% - 1rem);
        }
        @media (max-width: 575px) {
            width: calc(100% - 1rem);
        }
        h3 {
            text-transform: uppercase;
            font-size: 1.2rem;
            background: black;
            color: white;
            display: inline-block;
            padding: 0.4rem 0.4rem .2rem .4rem;
            margin-top: 1rem;
            margin-bottom: 0;
            transition: color .3s;
        }
        &:hover {
            @include box-shadow(0, 0, 15px, 2px, rgba(103, 103, 103, 0.16));
            h3 {
                color: #ffe765;
            }
        }
    }

}
.product {
    position: relative;
    padding: 1rem 2rem 2rem 2rem;
}
.product-img {
    text-align: center;
    //Aspect ratio of product images
    position: relative;
    width: 100%;
    padding-top: 60%;
    img {
        //Aspect ratio of product images
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        object-fit: contain;
        z-index: 1;
    }
}
.product-price {
    font-size: 1.4rem;
    font-weight: 800;
    color: inherit;
    white-space: nowrap;

    .old-price {
        font-size: 1.1rem;
        font-weight: 600;
        color: #545454;
    }
}
.product-add-to-cart {
    margin-top: 1rem;
}
