.product-ordering {
    @include flexbox();
    @include align-items(center);
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 2px;
    margin-left: auto;
    cursor: pointer;

    svg {
        width: .95rem;
        height: .95rem;
        transform: translateY(30%);
        margin-left: .4rem;
    }
}
