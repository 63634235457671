@import '../variables';
@import '../mixins/flex';

#breadcrumbs {
  padding: 1.5rem 0;
  &+ section {
	padding-top: 0;
  }
  font-weight: 700;
  text-transform: uppercase;
  ul {
	list-style: none;
	padding: 0;
	margin: 0;
	@include flexbox();
	@include flex-wrap(wrap);
	li {
	  a {
		color: $secondary-color;
		white-space: nowrap;
		&:hover {
		  color: darken($primary-color, 5%);
		}
	  }
	  &::after {
		content: '/';
		display: inline-block;
		padding: 0 .5rem;
	  }
	  &:last-child {
		color: darken($primary-color, 5%);
		&::after {
		  display: none;
		}
	  }
	}
  }
}
