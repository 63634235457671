@import '../variables';
@import '../mixins/placeholder';

/*Placeholder*/
input,
textarea {
    @include placeholder {
        color: $text-color;
        font-weight: 500;
        font-size: inherit;
        height: inherit;
        line-height: normal;
        transform: scale(0.9);
        transform-origin: 0% 50%;
        opacity: 1 !important;
    }
}
