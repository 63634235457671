#range-slider-wrap {
    max-width: 23rem;
}
#range-slider-inner {
    margin: 2rem 0;
    @media (max-width: 1199px) {
        margin: 1.6rem 0;
    }
}
#range-slider {
    width: calc(100% - 34px);
    margin: auto;
    border-radius: 0;
    .noUi-connects {
        border-radius: 0;
    }
    .noUi-handle:before,
    .noUi-handle:after {
        display: none;
    }
    .noUi-connect {
        background-color: #cccccc;
    }
}
.range-slider-item {
    font-size: 1.4rem;
    @media (max-width: 1199px) {
        font-size: 1.1rem;
    }
    font-weight: 800;
    margin-bottom: .3rem;
    &::before {
       content: 'â¬';
        display: inline-block;
        margin-right: .3rem;
    }
}
.noUi-horizontal {
    .noUi-handle {
        border: none;
        background: black;
        border-radius: 0;
        box-shadow: none;
        &:focus {
            outline: none;
        }
    }
}
