#product-detail-info-wrap {
    max-width: 70rem;
}

#product-detail-image-wrap {
    text-align: center;
}

.product-detail-image {
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: center;

    .product-detail-img {
        position: relative;
        width: 40rem;
        padding-top: 25rem;
        max-width: 100%;
        margin: 0 auto;

        img {
            //Aspect ratio of product images
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            object-fit: contain;
            z-index: 1;
        }
    }
}

.product-detail-badges {
    .product-badge {
        top: 0;
        right: auto;
        left: 0;

        &:nth-child(2) {
            transform: translateY(110%);
        }

        @media (max-width: 991px) {
            left: auto;
            right: 0;
        }
    }
}

.product-detail-gallery {
    position: relative;
    text-align: center;

    .swiper-container {
        max-width: calc(100% - 6rem);
    }

    .product-detail-gallery-img {
        position: relative;
        width: 50%;
        padding-top: 50%;
        max-width: 100%;
        margin: 0 auto;

        img {
            //Aspect ratio of product images
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            object-fit: contain;
            z-index: 1;
        }
    }
}

.product-detail-title {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
        background: black;
        color: white;
        font-size: 3rem;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 3rem 1rem .4rem 1rem;
        margin: 0 auto;
        display: inline-block;
        text-transform: uppercase;
        text-align: center;
    }

    .product-detail-title-small {
        margin-top: 1.8rem;
        letter-spacing: 2px;
        font-size: 1.5rem;
    }
}

.product-detail-title-small {
    font-size: 1.6rem;
    text-transform: unset;
    font-weight: 800;
    margin-top: 4rem;
}

.product-detail-description {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.3;
    margin-bottom: 3rem;
    @media (max-width: 991px) {
        margin: 0 auto 1.5rem auto;
    }
}

.product-detail-price-wrap {
    @include flexbox();
    @include align-items(flex-end);
    @include justify-content(flex-end);
    @media (max-width: 991px) {
        margin-bottom: .5rem;
    }
    @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.product-detail-variation {
    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);
    @include flex-wrap(wrap);
    margin: .5rem -.5rem;
    @media (max-width: 575px) {
        align-items: center;
        justify-content: center;
    }
}

.product-variation {
    padding: .8rem .5rem;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    background: $base-color;
    color: #777777;
    border: 1px solid #efefef;
    margin: 0.5rem;
    cursor: pointer;
    width: 5rem;
    box-shadow: 0 0 8px 1px rgba(103, 103, 103, 0.08);
    white-space: nowrap;
    transition: all .3s;

    &.selected {
        background: $secondary-color;
        color: white;
        border-color: $secondary-color;
    }
}

.product-detail-price {
    font-size: 4rem;
    font-weight: 900;
    line-height: 1;
    white-space: nowrap;
    @media (max-width: 1199px) and (min-width: 992px) {
        font-size: 2.8rem;
    }
    @media (max-width: 991px) {
        margin-top: 1rem;
    }
    @media (max-width: 575px) {
        font-size: 3.5rem;
        margin: 1rem 0;
    }

    .old-price {
        font-size: 2rem;
        font-weight: 600;
        color: #545454;
    }
}

.product-detail-quantity {
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 2rem;
    margin-bottom: .5rem;
    @media (max-width: 767px) {
        //justify-content: center;
    }
    @media (max-width: 575px) {
        margin-left: 0;
    }

    input {
        font-weight: inherit;
        text-align: center;
        border: 1px solid lighten($text-color, 70%);
        border-left: none;
        border-right: none;
        border-radius: 0;
        padding: .3rem .5rem;
        width: 3rem;

        &:focus {
            outline: none;
            border: 1px solid lighten($text-color, 70%);
            border-left: none;
            border-right: none;
            box-shadow: none;
        }
    }

}

.product-detail-quantity-nav {
    display: flex;
    justify-content: center;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: inherit;
        text-align: center;
        border: 1px solid lighten($text-color, 70%);
        border-radius: 0;
        padding: .5rem;
        background: white;

        &:focus {
            outline: none;
            border: 1px solid lighten($text-color, 70%);
            box-shadow: none;
        }

        img {
            width: 1.1rem;
            height: 1.1rem;
        }

        &:first-child {
            img {
                transform: rotate(-45deg);
                width: .5rem;
            }
        }

        &:last-child {
            img {
                transform: rotate(45deg);
                width: .5rem;
            }
        }
    }

    @media (max-width: 991px) {
        button {

        }
    }
    @media (max-width: 767px) {
        flex-direction: row;
    }
}

.product-detail-cart-wrap {
    display: flex;
    align-items: center;
    @include justify-content(flex-end);
    margin: 0 -.8rem;
    @media (max-width: 1199px) {
        margin: 0 -.5rem;
    }

    & > div {
        margin: 1rem .8rem;
        @media (max-width: 1199px) {
            margin: 1rem .5rem;
        }
    }

    @media (max-width: 575px) {
        justify-content: center;
    }

    .select {
        font-weight: inherit;
        text-align: center;
        border: 1px solid lighten($text-color, 70%);
        border-radius: 0;
        padding: .5rem;
        width: auto;
    }
}

.product-detail-available {
    .product-detail-stock {
        font-size: 1.5rem;
        color: $secondary-color;
        font-weight: 800;
    }

    div {
        line-height: 1;
    }

    span {
        line-height: 1;
        font-weight: 800;
    }
}

.product-detail-colors-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;
    @media (max-width: 575px) {
        justify-content: center;
    }
}

.product-detail-color-variation-wrap {
    margin-left: 1rem;
    margin-top: 1rem;
}

.product-color-variation {
    display: block;
    border: 1px solid #efefef;
    padding: 1rem;
    transition: all .3s;
    box-shadow: 0 0 8px 1px hsla(0, 0%, 40.4%, .08);
}

.product-color-variation:hover,
.product-color-variation.selected {
    border: 1px solid #cacaca;
}

.product-color-variation__img {
    width: 10rem;
    height: 10rem;
    object-fit: contain;
    @media (max-width: 1199px) {
        width: 9rem;
        height: 9rem;
    }
    @media (max-width: 991px) {
        width: 8rem;
        height: 8rem;
    }
    @media (max-width: 767px) {
        width: 7rem;
        height: 7rem;
    }
    @media (max-width: 575px) {
        width: 5rem;
        height: 5rem;
    }
}
