@import '../variables';
@import '../mixins/transition';
@import '../mixins/transform';
@import '../mixins/box-shadow';

/*Buttons*/
.own-btn {
    display: inline-block;
    position: relative;
    text-transform: lowercase;
    font-weight: 700;
    font-size: 1.1rem;
    font-family: $base-font-family;
    letter-spacing: 0;
    background: $text-color;
    color: #ffffff;
    padding: .8rem 2rem;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    border-radius: 1.2rem;
    @include box-shadow(0, 0, 15px, 3px, rgba(103, 103, 103, .3));
    @include transition(all .3s);

    &:focus {
        outline: 0;
    }

    &:hover {
        color: $text-color;
        background-color: $tertiary-color;
    }
    &--sm {
        @extend .own-btn;
        padding: .4rem 1.2rem;
        font-size: .85rem;
    }
    &--prev {
        @extend .own-btn;
        background: lighten($text-color, 55%);
        color: #ffffff;
        &:hover {
            background-color: $tertiary-color;
            color: $base-color;
        }
    }
    &--md-reversed {
        @extend .own-btn;
        padding: .4rem 1.2rem;
        font-size: 1rem;
        background: #ffffff;
        color: $text-color;
        @include box-shadow(0, 0, 10px, 3px, rgba(103, 103, 103, .2));
        &:hover {
            background-color: $tertiary-color;
            color: $base-color;
        }
    }
    &.danger {
        background: #f44336;
        color: white;
        &:hover {
            background: darken(#f44336, 10%);
        }
    }
    &.success {
        background: #8bc34a;
        color: white;
        &:hover {
            background: darken(#8bc34a, 10%);
        }
    }
    &.white {
        background: #ffffff;
        color: #000000;
        &:hover {
            background-color: $tertiary-color;
        }
    }
    &.grey {
        background: #a9a9a9;
        &:hover {
            color: $text-color;
            background-color: $tertiary-color;
        }
    }
}
