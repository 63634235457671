.heading-wrap {
    @media (max-width: 575px) {
        text-align: center;
    }
}
.heading-badge {
    display: inline-block;
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 6.25rem;
    @media (max-width: 575px) {
        font-size: 5rem;
    }
    line-height: 1;
    text-align: center;
    margin: 0;
    letter-spacing: 3px;
    span {
        display: block;
        font-size: 5.4rem;
        text-transform: lowercase;
        line-height: 1;
        font-weight: 400;
        letter-spacing: 1px;
    }
}
.heading-icon {
    position: relative;
    @media (max-width: 575px) {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    img {
        width: 3.38rem;
        height: 3.38rem;
    }
    &::before {
        display: none;
        content: '';
        width: 12rem;
        height: 2px;
        margin-right: 1.5rem;
        background: $secondary-color;
        @media (max-width: 575px) {
            display: inline-block;
            width: 9rem;
            max-width: 100%;
        }
    }
    &::after {
        content: '';
        display: inline-block;
        width: 12rem;
        height: 2px;
        margin-left: 1.5rem;
        background: $secondary-color;
        @media (max-width: 575px) {
            width: 9rem;
            max-width: 100%;
        }
    }
}
