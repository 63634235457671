/* banner */
.banner {
    position: relative;
    min-height: 100vh;

    .own-container,
    .own-container-fluid {
        height: 100%;
        min-height: inherit;
    }

    .banner-img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        bottom: 0;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: -1;
    }

    .banner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#ffffff, .6);
        z-index: 0;
    }

    .banner-content-wrap {
        height: 100%;
        min-height: inherit;
        font-family: $base-font-family;
    }

    .banner-content {
        z-index: 3;
        padding: $section-padding-md;
        @media (max-width: 991px) {
            padding: $section-padding-sm;
        }
        min-height: inherit;
        width: 100%;
    }
    .banner-text {
        max-width: 46rem;
        margin: 1rem auto 0;
        font-weight: 700;
        font-size: 1.2rem;
    }
    &-with-separator {
        &::after {
            content: '';
            position: absolute;
            bottom: -.5rem;
            left: 0;
            right: 0;
            height: 20rem;
            background-image: url("../../images/banner-overlay.png");
            z-index: 2;
            @media (max-width: 767px) {
                height: 15rem;
                background-position: right;
            }
        }
    }
    &-with-heading {
        h1 {
            text-transform: lowercase;
            font-size: 7rem;
            text-align: right;
            font-weight: 800;
            line-height: 1;
            margin-bottom: 0;

            span {
                display: block;
                line-height: 1;
                font-size: 26%;
                letter-spacing: 3px;
            }
            @media (max-width: 991px) {
                font-size: 5rem;
                span {
                    font-size: 36%;
                }
            }
            @media (max-width: 575px) {
                font-size: 3rem;
            }
        }
        .content {
            margin-top: 5rem;
            margin-left: 5rem;
            @media (max-width: 991px) {
                margin-top: 3rem;
                margin-left: 3rem;
                margin-bottom: 1rem;
            }
            @media (max-width: 575px) {
                text-align: center;
                margin-left: 0;
                h1 {
                    text-align: center;
                }
            }
        }
        .content-wrapper {
            @media (max-width: 575px) {
                width: 100%;
            }
        }
    }
    &-blog-detail {
        @extend .banner;
        min-height: 80vh;
        z-index: 2;
        @media (max-width: 991px) {
            min-height: 50vh;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            bottom: 0;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: -.5rem;
            left: 0;
            right: 0;
            height: 5rem;
            background-image: url("../../images/blog-detail-overlay.png");
            z-index: 2;
            @media (max-width: 767px) {
                height: 3rem;
            }
        }
    }
    &.half-banner {
        @media (max-width: 991px) {
            min-height: 60vh;
            &::after {
                background-position: right;
            }
        }
    }
    &.cart-banner {
        min-height: 80vh;
        @media (max-width: 991px) {
            min-height: 60vh;
        }
    }

}
