@import '../variables';
@import '../mixins/box-shadow';

/*Forms*/
form {
    input {
        border-color: transparent;
    }

    label {
    }
    .form-group {
        margin-bottom: 1.7rem;
        //&:last-child {
        //    margin-bottom: 0;
        //}
    }
}

.form-control {
    height: auto;
    box-shadow: none;
    font-weight: bold;
    border: 1px solid lighten($text-color, 70%);
    border-radius: 0.4rem;
    padding: 0.65rem 1.3rem;
    font-size: 1.1rem;

    &:focus {
        @include box-shadow(0, 0, 10px, 3px, rgba(0, 0, 0, .1));
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid black;
    -webkit-text-fill-color: $text-color;
    -webkit-box-shadow: none;
    background: transparent;
    transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


input[type=number] {
    -moz-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
