@charset "UTF-8";

/*Mixins*/

/*General*/

@import "~swiper/dist/css/swiper.min.css";

@import "~photoswipe/dist/photoswipe.css";

@import "~photoswipe/dist/default-skin/default-skin.css";

@import "~swiper/dist/css/swiper.min.css";

@import '~microtip/microtip.css';

@media (max-width: 991px) {
  html {
    font-size: 90%;
  }
}

a {
  color: inherit;
  outline: 0;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

body {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
  -moz-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  min-height: 100vh;
  background: #ffffff;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  overflow-x: hidden;
  min-width: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: #000000;
}

h2 {
  text-transform: lowercase;
  font-size: 4.2rem;
  font-weight: 600;
  line-height: 1;
}

h2 span {
  display: block;
  line-height: 1;
  font-size: 35%;
  letter-spacing: 6px;
}

main {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}

section {
  padding: 7rem 0;
}

@media (max-width: 991px) {
  section {
    padding: 5rem 0;
  }

  section.without-banner {
    padding-top: 6rem;
  }
}

textarea {
  resize: vertical;
}

/*Header*/

header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1001;
  color: #000000;
  text-transform: uppercase;
}

#header-wrapper.with-bg {
  background-color: rgba(255, 255, 255, 0.9);
}

/*Desktop header*/

.desktop-header {
  position: relative;
  top: 0;
  padding: 2.3rem 0;
  background-color: transparent;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  .desktop-header .own-container-fluid {
    padding-left: 1%;
    padding-right: 1%;
  }
}

.desktop-header .header-middle {
  position: relative;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.desktop-header .logo {
  height: auto;
  text-align: center;
}

.desktop-header .logo a {
  display: block;
  height: inherit;
  overflow: hidden;
}

.desktop-header .logo img {
  width: 15rem;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  .desktop-header .logo img {
    width: 11rem;
  }
}

@media (max-width: 991px) {
  .desktop-header {
    display: none;
  }
}

#desktop-menu {
  position: relative;
  list-style: none;
  margin: 0 1.5rem 0 0;
  padding: 0;
  -webkit-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s;
  -webkit-box-flex: 4;
  -moz-box-flex: 4;
  -webkit-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

@media (max-width: 1500px) {
  #desktop-menu {
    margin: 0 0.8rem 0 0;
  }
}

#desktop-menu > li {
  display: block;
  position: relative;
  margin: 0;
  color: #000000;
}

#desktop-menu > li a,
#desktop-menu > li .a {
  position: relative;
  display: block;
  cursor: pointer;
  color: inherit;
  font-weight: 900;
  font-size: 1.1rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

@media (max-width: 1500px) {
  #desktop-menu > li a,
  #desktop-menu > li .a {
    padding: 0.5rem 0.8rem;
    font-size: 1rem;
  }
}

@media (max-width: 1199px) {
  #desktop-menu > li a,
  #desktop-menu > li .a {
    padding: 0.5rem 0.6rem;
    font-size: 0.95rem;
  }
}

#desktop-menu > li:hover > a::after,
#desktop-menu > li:hover > .a::after {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

#desktop-menu > li.active {
  color: #2b2b2b;
}

#desktop-menu .menu-dropdown li a,
#desktop-menu .menu-dropdown li .a {
  text-transform: lowercase;
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 1.8rem;
  text-align: left;
  padding: 0;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.menu-item-dropdown-toggle {
  position: relative;
  cursor: pointer;
}

.menu-item-dropdown-toggle a::after {
  display: none;
}

.menu-item-dropdown-toggle:hover .menu-dropdown {
  visibility: visible;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  -o-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  opacity: 1;
}

.menu-item-dropdown-toggle .menu-dropdown-toggle-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.3rem;
}

.menu-dropdown {
  position: absolute;
  left: 50%;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0.8rem 0;
  color: #000000;
  background: #ffffff;
  border-radius: 0 0 2rem 2rem;
  cursor: auto;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.15);
  -webkit-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s, visibility 0.2s;
  -o-transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s, visibility 0.2s;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s, visibility 0.2s;
  -webkit-transform: translate3d(-50%, 30px, 0);
  -moz-transform: translate3d(-50%, 30px, 0);
  -ms-transform: translate3d(-50%, 30px, 0);
  -o-transform: translate3d(-50%, 30px, 0);
  transform: translate3d(-50%, 30px, 0);
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  z-index: 120;
}

@media (min-width: 768px) {
  .menu-dropdown {
    white-space: nowrap;
  }
}

.menu-dropdown li a {
  min-width: 9rem;
}

.menu-dropdown li:hover a {
  opacity: 0.7;
}

/*Mobile header*/

.mobile-header {
  display: none;
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem;
  min-width: 0;
  -webkit-transition: padding 0.3s;
  -o-transition: padding 0.3s;
  transition: padding 0.3s;
}

.mobile-header .logo {
  width: auto;
  height: auto;
  text-align: center;
  margin-right: 1rem;
}

.mobile-header .logo a {
  display: block;
  height: inherit;
  overflow: hidden;
}

.mobile-header .logo img {
  width: 10rem;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 991px) {
  .mobile-header {
    display: block;
  }
}

#header-wrapper.open #nav-icon .dot:nth-child(3),
#header-wrapper.open #nav-icon .dot:nth-child(4),
#header-wrapper.open #nav-icon .dot:nth-child(7),
#header-wrapper.open #nav-icon .dot:nth-child(8) {
  opacity: 0;
}

#header-wrapper.open #nav-icon .dash-2 {
  opacity: 0;
}

#header-wrapper.open #nav-icon .dash-1 {
  transform: rotate(-41deg);
}

#header-wrapper.open #nav-icon .dash-3 {
  transform: rotate(39deg);
}

#burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  z-index: 102;
  -webkit-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translateY(-105%);
  -moz-transform: translateY(-105%);
  -ms-transform: translateY(-105%);
  -o-transform: translateY(-105%);
  transform: translateY(-105%);
}

@media (min-width: 992px) {
  #burger-menu {
    display: none;
  }
}

#burger-menu.open {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

#burger-menu.open .menu-item {
  opacity: 1;
  transform: translateY(0);
}

#burger-menu.open .menu-item:nth-child(1) {
  transition-delay: 0.3s;
}

#burger-menu.open .menu-item:nth-child(2) {
  transition-delay: 0.5s;
}

#burger-menu.open .menu-item:nth-child(3) {
  transition-delay: 0.7s;
}

#burger-menu.open .menu-item:nth-child(4) {
  transition-delay: 0.9s;
}

#burger-menu.open .menu-item:nth-child(5) {
  transition-delay: 1.1s;
}

#burger-menu.open .menu-item:nth-child(6) {
  transition-delay: 1.3s;
}

#burger-menu .burger-menu-search-bar-wrap {
  display: flex;
  align-items: center;
  margin: 0 2rem;
}

#burger-menu .search-bar {
  margin: 0;
}

#burger-menu .search-bar input {
  width: 90%;
  min-width: auto;
}

#burger-menu .burger-menu-inner-close {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 5;
}

#burger-menu .burger-menu-inner-close svg {
  width: 1.8rem;
  height: 1.8rem;
}

#burger-menu .burger-menu-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5rem 1rem;
  text-align: center;
  margin: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-hidden-scrollable;
}

#burger-menu .burger-menu-list::-webkit-scrollbar {
  width: 0;
  display: none;
}

#burger-menu .burger-menu-list > li {
  flex-shrink: 0;
  padding-bottom: 2rem;
  text-transform: uppercase;
}

#burger-menu .burger-menu-list > li:first-child {
  margin-top: auto;
}

#burger-menu .burger-menu-list > li:last-child {
  margin-bottom: auto;
}

#burger-menu .burger-menu-list li {
  color: #000000;
  text-transform: uppercase;
}

#burger-menu .burger-menu-list li a {
  display: block;
  font-weight: 800;
  font-size: 1.6rem;
  padding: 0.3rem 1rem;
  margin: 0;
}

#burger-menu .burger-menu-list li.menu-item-dropdown-toggle svg {
  right: 11rem;
}

@media (max-width: 425px) {
  #burger-menu .burger-menu-list li.menu-item-dropdown-toggle svg {
    right: 13rem;
  }
}

#burger-menu .menu-item {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.3s;
}

#burger-menu .menu-dropdown {
  position: relative;
  visibility: visible;
  left: auto;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  background: transparent;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

#burger-menu .menu-dropdown li a {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0.5rem 2rem;
  margin: 0;
}

#burger-menu .menu-dropdown-toggle .menu-dropdown-toggle-mobile {
  display: block;
  color: inherit;
  cursor: pointer;
}

#burger-menu .menu-dropdown-toggle .menu-dropdown-toggle-mobile.open {
  transform: rotate(180deg);
}

.menu-item-dropdown {
  display: block;
  position: relative;
}

#nav-icon {
  width: 4rem;
  height: 3rem;
  margin-left: 0.5rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

@media (max-width: 991px) {
  #nav-icon {
    width: 2rem;
    height: auto;
  }
}

#nav-icon .dot {
  transition: all 0.3s;
  opacity: 1;
}

#nav-icon .dash-1,
#nav-icon .dash-3 {
  transform-origin: right;
  transition: all 0.3s;
}

#nav-icon .dash-2 {
  transition: all 0.3s;
}

/*Footer*/

footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 50;
  background: #000000;
  color: #ffffff;
  overflow: hidden;
}

footer a {
  color: #ffffff;
}

footer a:hover {
  color: white;
}

footer ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 0 0 0 -1rem;
  padding: 0;
}

footer ul li {
  margin-right: 1rem;
}

@media (max-width: 1199px) {
  footer ul {
    justify-content: center;
    margin: 0 0 1rem 0;
  }
}

@media (max-width: 767px) {
  footer ul {
    flex-direction: column;
  }

  footer ul li {
    margin-right: 0;
  }
}

footer .row {
  position: relative;
}

footer .row:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 40rem;
  height: 1px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#00ffffff",GradientType=1 );
  transform: translateX(-50%);
}

footer img {
  width: 3rem;
  height: 3rem;
  margin: 0 0.5rem;
}

/*Placeholder*/

input.placeholder,
textarea.placeholder {
  color: #000000;
  font-weight: 500;
  font-size: inherit;
  height: inherit;
  line-height: normal;
  transform: scale(0.9);
  transform-origin: 0% 50%;
  opacity: 1 !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #000000;
  font-weight: 500;
  font-size: inherit;
  height: inherit;
  line-height: normal;
  transform: scale(0.9);
  transform-origin: 0% 50%;
  opacity: 1 !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #000000;
  font-weight: 500;
  font-size: inherit;
  height: inherit;
  line-height: normal;
  transform: scale(0.9);
  transform-origin: 0% 50%;
  opacity: 1 !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #000000;
  font-weight: 500;
  font-size: inherit;
  height: inherit;
  line-height: normal;
  transform: scale(0.9);
  transform-origin: 0% 50%;
  opacity: 1 !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #000000;
  font-weight: 500;
  font-size: inherit;
  height: inherit;
  line-height: normal;
  transform: scale(0.9);
  transform-origin: 0% 50%;
  opacity: 1 !important;
}

/*Privacy policy*/

#personal-data h3 {
  margin-top: 3rem;
}

#personal-data h3:first-child {
  margin-top: 0;
}

#personal-data .first-level {
  padding-left: 30px;
}

#personal-data .second-level {
  padding-left: 60px;
}

#personal-data a {
  word-wrap: break-word;
}

#personal-data .highlighted {
  font-style: italic;
  font-size: 1.5rem;
}

/*Print*/

* {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.offset {
  padding: 7rem 0;
}

@media (max-width: 991px) {
  .offset {
    padding: 5rem 0;
  }
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: #ff0b0b;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

.font-size-90 {
  font-size: 90%;
}

.font-size-80 {
  font-size: 80%;
}

/*Alert*/

.alert {
  width: 34rem;
  max-width: calc(100% - 3.5rem);
  height: auto;
  max-height: 200px;
  min-height: 50px;
  position: fixed;
  bottom: 1.75rem;
  right: 1.75rem;
  margin-bottom: 0;
  z-index: 9999;
  font-weight: 400;
  font-size: 1rem;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  color: #000000;
  border-radius: 4px;
  border-left: 4px solid transparent;
  overflow: hidden;
  padding: 1.4rem 3.5rem 1.4rem 4.5rem;
  transition: all 1s;
  opacity: 0;
  -webkit-transform: translateY(200%);
  transform: translateY(200%);
}

.alert strong {
  display: block;
}

.alert p {
  margin: 0;
  color: #4d4d4d;
}

.alert.showed {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.alert-icon-wrap {
  position: absolute;
  top: 50%;
  left: 1.3rem;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  padding: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.alert-close {
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 1.5rem;
  cursor: pointer;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
}

.alert-close-icon {
  width: 1rem;
  height: 1rem;
  background-image: url("./../images/alerts/close.svg");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.alert.alert-danger {
  border-color: #ff3636;
}

.alert.alert-danger .alert-icon-wrap {
  background: #ff3636;
}

.alert.alert-danger .alert-icon {
  background-image: url("./../images/alerts/danger.svg");
}

.alert.alert-success {
  border-color: #5fbc63;
}

.alert.alert-success .alert-icon-wrap {
  background: #5fbc63;
}

.alert.alert-success .alert-icon {
  background-image: url("./../images/alerts/success.svg");
}

.alert.alert-info {
  border-color: #17b6e9;
}

.alert.alert-info .alert-icon-wrap {
  background: #17b6e9;
}

.alert.alert-info .alert-icon {
  background-image: url("./../images/alerts/info.svg");
}

.alert.alert-warning {
  border-color: #DBA507;
}

.alert.alert-warning .alert-icon-wrap {
  background: #DBA507;
}

.alert.alert-warning .alert-icon {
  background-image: url("./../images/alerts/warning.svg");
}

#up {
  position: fixed;
  right: 0;
  bottom: 0;
  line-height: 4rem;
  height: 4rem;
  width: 4rem;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 0.8rem;
  margin: 1.5rem;
  background-color: #000000;
  cursor: pointer;
  opacity: 0;
  -webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(0, 8rem, 0);
  -moz-transform: translate3d(0, 8rem, 0);
  -ms-transform: translate3d(0, 8rem, 0);
  -o-transform: translate3d(0, 8rem, 0);
  transform: translate3d(0, 8rem, 0);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 999;
}

#up::before {
  content: " ";
  display: block;
  position: absolute;
  top: 1.7rem;
  left: 1.25rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 5px solid #ffffff;
  border-top-style: none;
  border-right-style: none;
  -webkit-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  transition: border-color 0.3s;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#up.active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#up:hover {
  background: rgba(0, 0, 0, 0.58);
}

#up:hover::before {
  border-color: #ffffff;
}

/* banner */

.banner,
.banner-blog-detail {
  position: relative;
  min-height: 100vh;
}

.banner .own-container,
.banner-blog-detail .own-container,
.banner .own-container-fluid,
.banner-blog-detail .own-container-fluid {
  height: 100%;
  min-height: inherit;
}

.banner .banner-img,
.banner-blog-detail .banner-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
}

.banner .banner-overlay,
.banner-blog-detail .banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 0;
}

.banner .banner-content-wrap,
.banner-blog-detail .banner-content-wrap {
  height: 100%;
  min-height: inherit;
  font-family: "Nunito", sans-serif;
}

.banner .banner-content,
.banner-blog-detail .banner-content {
  z-index: 3;
  padding: 7rem 0;
  min-height: inherit;
  width: 100%;
}

@media (max-width: 991px) {
  .banner .banner-content,
  .banner-blog-detail .banner-content {
    padding: 5rem 0;
  }
}

.banner .banner-text,
.banner-blog-detail .banner-text {
  max-width: 46rem;
  margin: 1rem auto 0;
  font-weight: 700;
  font-size: 1.2rem;
}

.banner-with-separator::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  height: 20rem;
  background-image: url("./../images/banner-overlay.png");
  z-index: 2;
}

@media (max-width: 767px) {
  .banner-with-separator::after {
    height: 15rem;
    background-position: right;
  }
}

.banner-with-heading h1 {
  text-transform: lowercase;
  font-size: 7rem;
  text-align: right;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 0;
}

.banner-with-heading h1 span {
  display: block;
  line-height: 1;
  font-size: 26%;
  letter-spacing: 3px;
}

@media (max-width: 991px) {
  .banner-with-heading h1 {
    font-size: 5rem;
  }

  .banner-with-heading h1 span {
    font-size: 36%;
  }
}

@media (max-width: 575px) {
  .banner-with-heading h1 {
    font-size: 3rem;
  }
}

.banner-with-heading .content {
  margin-top: 5rem;
  margin-left: 5rem;
}

@media (max-width: 991px) {
  .banner-with-heading .content {
    margin-top: 3rem;
    margin-left: 3rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 575px) {
  .banner-with-heading .content {
    text-align: center;
    margin-left: 0;
  }

  .banner-with-heading .content h1 {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .banner-with-heading .content-wrapper {
    width: 100%;
  }
}

.banner-blog-detail {
  min-height: 80vh;
  z-index: 2;
}

@media (max-width: 991px) {
  .banner-blog-detail {
    min-height: 50vh;
  }
}

.banner-blog-detail img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.banner-blog-detail::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  height: 5rem;
  background-image: url("./../images/blog-detail-overlay.png");
  z-index: 2;
}

@media (max-width: 767px) {
  .banner-blog-detail::after {
    height: 3rem;
  }
}

@media (max-width: 991px) {
  .banner.half-banner,
  .half-banner.banner-blog-detail {
    min-height: 60vh;
  }

  .banner.half-banner::after,
  .half-banner.banner-blog-detail::after {
    background-position: right;
  }
}

.banner.cart-banner,
.cart-banner.banner-blog-detail {
  min-height: 80vh;
}

@media (max-width: 991px) {
  .banner.cart-banner,
  .cart-banner.banner-blog-detail {
    min-height: 60vh;
  }
}

/*Buttons*/

.own-btn,
.own-btn--md-reversed,
.own-btn--prev,
.own-btn--sm {
  display: inline-block;
  position: relative;
  text-transform: lowercase;
  font-weight: 700;
  font-size: 1.1rem;
  font-family: "Nunito", sans-serif;
  letter-spacing: 0;
  background: #000000;
  color: #ffffff;
  padding: 0.8rem 2rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  border-radius: 1.2rem;
  -webkit-box-shadow: 0 0 15px 3px rgba(103, 103, 103, 0.3);
  -moz-box-shadow: 0 0 15px 3px rgba(103, 103, 103, 0.3);
  box-shadow: 0 0 15px 3px rgba(103, 103, 103, 0.3);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.own-btn:focus,
.own-btn--md-reversed:focus,
.own-btn--prev:focus,
.own-btn--sm:focus {
  outline: 0;
}

.own-btn:hover,
.own-btn--md-reversed:hover,
.own-btn--prev:hover,
.own-btn--sm:hover {
  color: #000000;
  background-color: #ffcf37;
}

.own-btn--sm {
  padding: 0.4rem 1.2rem;
  font-size: 0.85rem;
}

.own-btn--prev {
  background: #8c8c8c;
  color: #ffffff;
}

.own-btn--prev:hover {
  background-color: #ffcf37;
  color: #ffffff;
}

.own-btn--md-reversed {
  padding: 0.4rem 1.2rem;
  font-size: 1rem;
  background: #ffffff;
  color: #000000;
  -webkit-box-shadow: 0 0 10px 3px rgba(103, 103, 103, 0.2);
  -moz-box-shadow: 0 0 10px 3px rgba(103, 103, 103, 0.2);
  box-shadow: 0 0 10px 3px rgba(103, 103, 103, 0.2);
}

.own-btn--md-reversed:hover {
  background-color: #ffcf37;
  color: #ffffff;
}

.own-btn.danger,
.danger.own-btn--sm,
.danger.own-btn--prev,
.danger.own-btn--md-reversed {
  background: #f44336;
  color: white;
}

.own-btn.danger:hover,
.danger.own-btn--sm:hover,
.danger.own-btn--prev:hover,
.danger.own-btn--md-reversed:hover {
  background: #ea1c0d;
}

.own-btn.success,
.success.own-btn--sm,
.success.own-btn--prev,
.success.own-btn--md-reversed {
  background: #8bc34a;
  color: white;
}

.own-btn.success:hover,
.success.own-btn--sm:hover,
.success.own-btn--prev:hover,
.success.own-btn--md-reversed:hover {
  background: #71a436;
}

.own-btn.white,
.white.own-btn--sm,
.white.own-btn--prev,
.white.own-btn--md-reversed {
  background: #ffffff;
  color: #000000;
}

.own-btn.white:hover,
.white.own-btn--sm:hover,
.white.own-btn--prev:hover,
.white.own-btn--md-reversed:hover {
  background-color: #ffcf37;
}

.own-btn.grey,
.grey.own-btn--sm,
.grey.own-btn--prev,
.grey.own-btn--md-reversed {
  background: #a9a9a9;
}

.own-btn.grey:hover,
.grey.own-btn--sm:hover,
.grey.own-btn--prev:hover,
.grey.own-btn--md-reversed:hover {
  color: #000000;
  background-color: #ffcf37;
}

#kontakt {
  position: relative;
  z-index: 2;
  max-width: 1640px;
  margin: 0 auto;
}

#kontakt h3 {
  font-size: 2.19rem;
  color: #000000;
  margin-bottom: 2rem;
}

#kontakt .contact-form-wrap {
  height: 100%;
  max-width: 40rem;
  margin: 0 auto;
}

@media (max-width: 767px) {
  #kontakt .contact-form-wrap {
    max-width: 100%;
  }
}

/*Forms*/

form input {
  border-color: transparent;
}

form .form-group {
  margin-bottom: 1.7rem;
}

.form-control {
  height: auto;
  box-shadow: none;
  font-weight: bold;
  border: 1px solid #b3b3b3;
  border-radius: 0.4rem;
  padding: 0.65rem 1.3rem;
  font-size: 1.1rem;
}

.form-control:focus {
  -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid black;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: none;
  background: transparent;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*Mixins*/

/*Checkbox*/

.checkbox {
  position: relative;
  cursor: pointer;
}

.checkbox .checkbox-label {
  position: relative;
  display: inline-block;
  padding-left: 2rem;
  letter-spacing: 1px;
  font-size: 0.9rem;
  text-align: left;
}

.checkbox .checkbox-label:before {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  opacity: 0;
  background-image: url("./../images/icons/check.svg");
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.checkbox .checkbox-label:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 0.4rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.checkbox input {
  position: absolute;
  top: 50%;
  height: 1.5rem;
  width: 1.5rem;
  opacity: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.checkbox input:checked ~ .checkbox-label:before {
  opacity: 1;
}

#breadcrumbs {
  padding: 1.5rem 0;
  font-weight: 700;
  text-transform: uppercase;
}

#breadcrumbs + section {
  padding-top: 0;
}

#breadcrumbs ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#breadcrumbs ul li a {
  color: #03a189;
  white-space: nowrap;
}

#breadcrumbs ul li a:hover {
  color: #1e1e1e;
}

#breadcrumbs ul li::after {
  content: "/";
  display: inline-block;
  padding: 0 0.5rem;
}

#breadcrumbs ul li:last-child {
  color: #1e1e1e;
}

#breadcrumbs ul li:last-child::after {
  display: none;
}

.headroom {
  position: fixed;
  width: 100%;
  z-index: 100;
  background: transparent;
  transition: all 0.3s;
  will-change: transform;
}

.headroom::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 3;
  transform: translateY(-100%);
  transition: all 0.3s;
}

@media (max-width: 991px) {
  .headroom.active {
    background: #ffffff;
  }

  .headroom.active::after {
    height: 100%;
  }
}

.headroom.active::after {
  transform: translateY(0);
}

.headroom.active .search-bar {
  opacity: 1;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .headroom.active .search-bar {
    transform: translateY(0);
    transition-delay: 0.3s;
  }
}

.headroom.active .js-searchToggle #close-svg {
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s;
}

.headroom.active .js-searchToggle #open-svg {
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s;
}

.headroom--pinned {
  transform: translateY(0%);
}

.headroom--not-top {
  background: #ffffff;
  -webkit-box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.05);
}

.headroom--unpinned {
  transform: translateY(-150%);
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-6 {
  z-index: 6;
}

.z-7 {
  z-index: 7;
}

.z-8 {
  z-index: 8;
}

.z-9 {
  z-index: 9;
}

.second-font-family {
  font-family: "Nunito", sans-serif;
}

.own-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 8%;
}

@media (max-width: 1199px) {
  .own-container {
    padding: 0 4%;
  }
}

.own-container-fluid {
  width: 100%;
  max-width: 2100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 4%;
}

@media (max-width: 575px) {
  .heading-wrap {
    text-align: center;
  }
}

.heading-badge {
  display: inline-block;
  color: #2b2b2b;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 6.25rem;
  line-height: 1;
  text-align: center;
  margin: 0;
  letter-spacing: 3px;
}

@media (max-width: 575px) {
  .heading-badge {
    font-size: 5rem;
  }
}

.heading-badge span {
  display: block;
  font-size: 5.4rem;
  text-transform: lowercase;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 1px;
}

.heading-icon {
  position: relative;
}

@media (max-width: 575px) {
  .heading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.heading-icon img {
  width: 3.38rem;
  height: 3.38rem;
}

.heading-icon::before {
  display: none;
  content: "";
  width: 12rem;
  height: 2px;
  margin-right: 1.5rem;
  background: #03a189;
}

@media (max-width: 575px) {
  .heading-icon::before {
    display: inline-block;
    width: 9rem;
    max-width: 100%;
  }
}

.heading-icon::after {
  content: "";
  display: inline-block;
  width: 12rem;
  height: 2px;
  margin-left: 1.5rem;
  background: #03a189;
}

@media (max-width: 575px) {
  .heading-icon::after {
    width: 9rem;
    max-width: 100%;
  }
}

.offer {
  position: relative;
  max-width: 60rem;
  margin: 1.5rem 0;
}

@media (max-width: 991px) {
  .offer {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .offer {
    margin: 3rem 0;
  }
}

@media (max-width: 575px) {
  .offer {
    text-align: center;
  }
}

.offer-item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  margin-top: 1.5rem;
}

@media (max-width: 575px) {
  .offer-item {
    position: relative;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    margin-top: 4.5rem;
  }
}

.offer-item > div:first-child {
  margin-right: 0.5rem;
}

@media (max-width: 575px) {
  .offer-item > div:first-child {
    margin-right: 0;
  }
}

.offer .day {
  font-size: 2.65rem;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 3px;
}

.offer .name {
  font-size: 2.56rem;
  line-height: 1;
  color: #2b2b2b;
  margin-bottom: 0;
  max-width: 40rem;
}

.offer .alergens {
  font-size: 1.9rem;
  color: #5e5e5e;
}

.offer .price {
  display: inline-block;
  font-size: 2.6rem;
  font-weight: 900;
  line-height: 1;
  letter-spacing: 2px;
  white-space: nowrap;
}

@media (max-width: 575px) {
  .offer .price {
    margin-top: 1rem;
  }
}

.offer-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-top: 2.5rem;
}

@media (max-width: 991px) {
  .offer-wrapper {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 4rem;
  }
}

.offer-wrapper .offer::after {
  content: "";
  display: block;
  width: 12rem;
  height: 2px;
  max-width: 100%;
  margin: 4rem auto;
  background: #03a189;
}

.offer-wrapper .offer:last-child::after {
  display: none;
}

.offer-wrapper > div {
  width: 50%;
}

@media (max-width: 991px) {
  .offer-wrapper > div {
    width: 100%;
  }
}

.offer-wrapper > div:first-child {
  padding-right: 3rem;
  position: relative;
}

.offer-wrapper > div:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: #03a189;
}

@media (max-width: 991px) {
  .offer-wrapper > div:first-child {
    padding-right: 0;
  }

  .offer-wrapper > div:first-child::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .offer-wrapper > div:first-child .offer:last-child {
    margin-bottom: 0 !important;
  }
}

.offer-wrapper > div:last-child {
  padding-left: 3rem;
}

@media (max-width: 991px) {
  .offer-wrapper > div:last-child {
    padding-left: 0;
  }
}

#about {
  position: relative;
  z-index: 2;
  max-width: 1640px;
  margin: 0 auto;
}

#about h2 {
  text-transform: none;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

#about h3 {
  font-weight: 900;
  font-size: 1.3rem;
}

#cookies-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  font-size: 0.9rem;
  padding: 1rem 2rem;
  z-index: 99999;
  -webkit-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

@media (max-width: 767px) {
  #cookies-bar {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
}

#cookies-bar.active {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}

#cookies-bar .cookies-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  white-space: nowrap;
}

@media (max-width: 767px) {
  #cookies-bar .cookies-nav {
    margin-top: 1.5rem;
  }
}

#cookies-bar .cookies-btn {
  display: inline-block;
  position: relative;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  box-shadow: none;
  border-radius: 0.8rem;
  background: #000000;
  padding: 0.4rem 1.4rem;
  margin: 0 1.5rem;
  font-size: 0.9em;
}

#cookies-bar .cookies-btn:focus {
  box-shadow: none;
  outline: 0;
}

@media (max-width: 991px) {
  #cookies-bar {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  #cookies-bar {
    font-size: 1.3rem;
  }
}

@media (max-width: 575px) {
  #cookies-bar {
    font-size: 1.4rem;
  }
}

.cart {
  position: relative;
  margin: 0 0.5rem 0 0;
}

.cart svg {
  width: 2rem;
  height: 2rem;
  color: inherit;
}

@media (max-width: 991px) {
  .cart {
    width: 2rem;
  }

  .cart svg {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.cart .cart-counter {
  position: absolute;
  bottom: -0.9rem;
  right: -0.65rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  background: #8BC34A;
  transition: background 0.3s;
  color: #ffffff;
}

.cart .cart-counter.active {
  transition: transform 0.5s, background 0.3s;
  background: #f44336;
  transform: rotate(360deg);
}

.profile {
  position: relative;
  margin: 0 0.5rem;
}

.profile svg {
  width: 2rem;
  height: 2rem;
  color: inherit;
}

.search {
  margin: 0 0.5rem;
}

.search svg {
  width: 2rem;
  height: 2rem;
  color: inherit;
}

@media (max-width: 991px) {
  .search svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.js-searchToggle {
  position: relative;
  z-index: 5;
  cursor: pointer;
}

.js-searchToggle #close-svg {
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: all 0.3s;
}

.js-searchToggle #open-svg {
  opacity: 1;
  transform: scale(1);
  transform-origin: center;
  transition: all 0.3s;
}

.search-bar {
  position: absolute;
  margin: 0;
  top: 50%;
  right: 0;
  z-index: 4;
  width: 100%;
  padding: 1rem;
  text-align: center;
  transform: translateY(-200%);
  transition: all 0.3s;
}

.search-bar input {
  width: 40rem;
  font-size: 1.3rem;
  outline: none;
  outline-offset: 0;
  border: 1px solid #f0f0f0;
  border-radius: 0.4rem;
  padding: 0.3rem 1rem;
  margin: 0;
  height: inherit;
  vertical-align: middle;
  -webkit-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  -webkit-box-shadow: 0 0 7px 2px rgba(103, 103, 103, 0.18);
  -moz-box-shadow: 0 0 7px 2px rgba(103, 103, 103, 0.18);
  box-shadow: 0 0 7px 2px rgba(103, 103, 103, 0.18);
}

.search-bar input:focus {
  -webkit-box-shadow: 0 0 7px 3px rgba(103, 103, 103, 0.25);
  -moz-box-shadow: 0 0 7px 3px rgba(103, 103, 103, 0.25);
  box-shadow: 0 0 7px 3px rgba(103, 103, 103, 0.25);
}

@media (max-width: 991px) {
  .search-bar {
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }

  .search-bar input {
    max-width: 100%;
  }
}

.page-title h2,
.page-title h1 {
  display: inline-block;
  font-size: 5rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .page-title h2,
  .page-title h1 {
    font-size: 4rem;
  }
}

@media (max-width: 575px) {
  .page-title h2,
  .page-title h1 {
    font-size: 3rem;
  }
}

@media (max-width: 425px) {
  .page-title h2,
  .page-title h1 {
    display: block;
    font-size: 2rem;
    text-align: center;
  }
}

.product-grid {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin: 0 -0.5rem;
}

.product-grid .product-grid-item {
  background: white;
  margin: 0.5rem;
  border-radius: 1.5rem;
  transition: box-shadow 0.3s;
  width: calc(33.33% - 1rem);
}

@media (max-width: 767px) {
  .product-grid .product-grid-item {
    width: calc(50% - 1rem);
  }
}

@media (max-width: 575px) {
  .product-grid .product-grid-item {
    width: calc(100% - 1rem);
  }
}

.product-grid .product-grid-item h3 {
  text-transform: uppercase;
  font-size: 1.2rem;
  background: black;
  color: white;
  display: inline-block;
  padding: 0.4rem 0.4rem 0.2rem 0.4rem;
  margin-top: 1rem;
  margin-bottom: 0;
  transition: color 0.3s;
}

.product-grid .product-grid-item:hover {
  -webkit-box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
  -moz-box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
  box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
}

.product-grid .product-grid-item:hover h3 {
  color: #ffe765;
}

.product {
  position: relative;
  padding: 1rem 2rem 2rem 2rem;
}

.product-img {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 60%;
}

.product-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: contain;
  z-index: 1;
}

.product-price {
  font-size: 1.4rem;
  font-weight: 800;
  color: inherit;
  white-space: nowrap;
}

.product-price .old-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: #545454;
}

.product-add-to-cart {
  margin-top: 1rem;
}

#product-detail-info-wrap {
  max-width: 70rem;
}

#product-detail-image-wrap {
  text-align: center;
}

.product-detail-image {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
}

.product-detail-image .product-detail-img {
  position: relative;
  width: 40rem;
  padding-top: 25rem;
  max-width: 100%;
  margin: 0 auto;
}

.product-detail-image .product-detail-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: contain;
  z-index: 1;
}

.product-detail-badges .product-badge,
.product-detail-badges .product-badge-news,
.product-detail-badges .product-badge-sale,
.product-detail-badges .product-badge-action,
.product-detail-badges .product-badge-tip {
  top: 0;
  right: auto;
  left: 0;
}

.product-detail-badges .product-badge:nth-child(2),
.product-detail-badges .product-badge-news:nth-child(2),
.product-detail-badges .product-badge-sale:nth-child(2),
.product-detail-badges .product-badge-action:nth-child(2),
.product-detail-badges .product-badge-tip:nth-child(2) {
  transform: translateY(110%);
}

@media (max-width: 991px) {
  .product-detail-badges .product-badge,
  .product-detail-badges .product-badge-news,
  .product-detail-badges .product-badge-sale,
  .product-detail-badges .product-badge-action,
  .product-detail-badges .product-badge-tip {
    left: auto;
    right: 0;
  }
}

.product-detail-gallery {
  position: relative;
  text-align: center;
}

.product-detail-gallery .swiper-container {
  max-width: calc(100% - 6rem);
}

.product-detail-gallery .product-detail-gallery-img {
  position: relative;
  width: 50%;
  padding-top: 50%;
  max-width: 100%;
  margin: 0 auto;
}

.product-detail-gallery .product-detail-gallery-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: contain;
  z-index: 1;
}

.product-detail-title {
  text-align: center;
  margin-bottom: 2rem;
}

.product-detail-title h1 {
  background: black;
  color: white;
  font-size: 3rem;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 3rem 1rem 0.4rem 1rem;
  margin: 0 auto;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
}

.product-detail-title .product-detail-title-small {
  margin-top: 1.8rem;
  letter-spacing: 2px;
  font-size: 1.5rem;
}

.product-detail-title-small {
  font-size: 1.6rem;
  text-transform: unset;
  font-weight: 800;
  margin-top: 4rem;
}

.product-detail-description {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.3;
  margin-bottom: 3rem;
}

@media (max-width: 991px) {
  .product-detail-description {
    margin: 0 auto 1.5rem auto;
  }
}

.product-detail-price-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
}

@media (max-width: 991px) {
  .product-detail-price-wrap {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 575px) {
  .product-detail-price-wrap {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.product-detail-variation {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0.5rem -0.5rem;
}

@media (max-width: 575px) {
  .product-detail-variation {
    align-items: center;
    justify-content: center;
  }
}

.product-variation {
  padding: 0.8rem 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  background: #ffffff;
  color: #777777;
  border: 1px solid #efefef;
  margin: 0.5rem;
  cursor: pointer;
  width: 5rem;
  box-shadow: 0 0 8px 1px rgba(103, 103, 103, 0.08);
  white-space: nowrap;
  transition: all 0.3s;
}

.product-variation.selected {
  background: #03a189;
  color: white;
  border-color: #03a189;
}

.product-detail-price {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
  white-space: nowrap;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .product-detail-price {
    font-size: 2.8rem;
  }
}

@media (max-width: 991px) {
  .product-detail-price {
    margin-top: 1rem;
  }
}

@media (max-width: 575px) {
  .product-detail-price {
    font-size: 3.5rem;
    margin: 1rem 0;
  }
}

.product-detail-price .old-price {
  font-size: 2rem;
  font-weight: 600;
  color: #545454;
}

.product-detail-quantity {
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 575px) {
  .product-detail-quantity {
    margin-left: 0;
  }
}

.product-detail-quantity input {
  font-weight: inherit;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0.3rem 0.5rem;
  width: 3rem;
}

.product-detail-quantity input:focus {
  outline: none;
  border: 1px solid #b3b3b3;
  border-left: none;
  border-right: none;
  box-shadow: none;
}

.product-detail-quantity-nav {
  display: flex;
  justify-content: center;
}

.product-detail-quantity-nav button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: inherit;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  padding: 0.5rem;
  background: white;
}

.product-detail-quantity-nav button:focus {
  outline: none;
  border: 1px solid #b3b3b3;
  box-shadow: none;
}

.product-detail-quantity-nav button img {
  width: 1.1rem;
  height: 1.1rem;
}

.product-detail-quantity-nav button:first-child img {
  transform: rotate(-45deg);
  width: 0.5rem;
}

.product-detail-quantity-nav button:last-child img {
  transform: rotate(45deg);
  width: 0.5rem;
}

@media (max-width: 767px) {
  .product-detail-quantity-nav {
    flex-direction: row;
  }
}

.product-detail-cart-wrap {
  display: flex;
  align-items: center;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  margin: 0 -0.8rem;
}

@media (max-width: 1199px) {
  .product-detail-cart-wrap {
    margin: 0 -0.5rem;
  }
}

.product-detail-cart-wrap > div {
  margin: 1rem 0.8rem;
}

@media (max-width: 1199px) {
  .product-detail-cart-wrap > div {
    margin: 1rem 0.5rem;
  }
}

@media (max-width: 575px) {
  .product-detail-cart-wrap {
    justify-content: center;
  }
}

.product-detail-cart-wrap .select {
  font-weight: inherit;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  padding: 0.5rem;
  width: auto;
}

.product-detail-available .product-detail-stock {
  font-size: 1.5rem;
  color: #03a189;
  font-weight: 800;
}

.product-detail-available div {
  line-height: 1;
}

.product-detail-available span {
  line-height: 1;
  font-weight: 800;
}

.product-detail-colors-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

@media (max-width: 575px) {
  .product-detail-colors-wrap {
    justify-content: center;
  }
}

.product-detail-color-variation-wrap {
  margin-left: 1rem;
  margin-top: 1rem;
}

.product-color-variation {
  display: block;
  border: 1px solid #efefef;
  padding: 1rem;
  transition: all 0.3s;
  box-shadow: 0 0 8px 1px rgba(103, 103, 103, 0.08);
}

.product-color-variation:hover,
.product-color-variation.selected {
  border: 1px solid #cacaca;
}

.product-color-variation__img {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
}

@media (max-width: 1199px) {
  .product-color-variation__img {
    width: 9rem;
    height: 9rem;
  }
}

@media (max-width: 991px) {
  .product-color-variation__img {
    width: 8rem;
    height: 8rem;
  }
}

@media (max-width: 767px) {
  .product-color-variation__img {
    width: 7rem;
    height: 7rem;
  }
}

@media (max-width: 575px) {
  .product-color-variation__img {
    width: 5rem;
    height: 5rem;
  }
}

.product-ordering {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 2px;
  margin-left: auto;
  cursor: pointer;
}

.product-ordering svg {
  width: 0.95rem;
  height: 0.95rem;
  transform: translateY(30%);
  margin-left: 0.4rem;
}

.banner-floated {
  position: absolute;
  top: 50%;
  right: 0;
  max-width: 18rem;
  background: #ffcf37;
  padding: 3.5rem 1rem 1.7rem 2.5rem;
  border-radius: 3rem 0 0 3rem;
  transform: translate(100%, -50%);
  transition: transform 0.5s;
}

@media (max-width: 991px) {
  .banner-floated {
    position: relative;
    max-width: 100%;
    width: 50rem;
    top: auto;
    right: auto;
    margin: auto auto 0 auto;
    border-radius: 2rem;
    padding: 1.5rem;
    transform: translate(150%, 0%);
  }
}

@media (max-width: 575px) {
  .banner-floated {
    text-align: center;
  }
}

.banner-floated.active {
  transform: translate(0, -50%);
}

@media (max-width: 991px) {
  .banner-floated.active {
    transform: translate(0%, 0%);
  }
}

.banner-floated h3 {
  font-weight: 900;
  font-size: 3rem;
}

@media (max-width: 991px) {
  .banner-floated h3 {
    font-size: 2.5rem;
  }
}

.banner-floated h3 small {
  display: block;
  font-size: 45%;
  font-weight: 600;
}

.banner-floated p {
  font-size: 1.2rem;
  font-weight: 500;
}

@media (max-width: 991px) {
  .banner-floated p {
    font-size: 1rem;
  }
}

.banner-floated .own-btn,
.banner-floated .own-btn--sm,
.banner-floated .own-btn--prev,
.banner-floated .own-btn--md-reversed {
  background-color: #3f535f;
  border-radius: 3rem;
}

.banner-floated .own-btn:hover,
.banner-floated .own-btn--sm:hover,
.banner-floated .own-btn--prev:hover,
.banner-floated .own-btn--md-reversed:hover {
  background-color: #ffffff;
}

.banner-floated img {
  position: absolute;
  top: 1.3rem;
  left: 1.7rem;
  width: 2rem;
  height: 2rem;
}

@media (max-width: 991px) {
  .banner-floated img {
    display: none;
  }
}

.scroll-down {
  position: absolute;
  bottom: 0;
  left: 50%;
  background: #ffffff;
  padding: 0.5rem;
  border-radius: 4rem 4rem 0 0;
  min-width: 13rem;
  text-align: center;
  transform: translate(-50%, 100%);
  opacity: 0;
  transition: opacity 0.3s, transform 0.7s;
}

.scroll-down .icon-scroll {
  width: 28px;
  height: 45px;
  box-shadow: inset 0 0 0 3px #000;
  border-radius: 25px;
  margin: 0 auto;
}

.scroll-down .icon-scroll::before {
  display: block;
  content: "";
  width: 3px;
  height: 10px;
  background: #000;
  margin: 0 auto;
  position: relative;
  top: 8px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

.scroll-down.active {
  transform: translate(-50%, 0%);
  opacity: 1;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(22px);
  }
}

#newsletter {
  position: relative;
  background: #efefef;
  padding: 6rem 0;
  margin-top: 4rem;
}

@media (max-width: 991px) {
  #newsletter {
    margin-top: 0;
    padding: 3rem 0;
  }
}

.newsletter-wrap::before {
  content: "";
  position: absolute;
  top: -5rem;
  right: 70%;
  width: 30rem;
  height: calc(100% + 5rem);
  background-image: url("./../images/newsletter/newsletter-1.png");
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.newsletter-wrap::after {
  content: "";
  position: absolute;
  top: -5rem;
  left: 70%;
  width: 30rem;
  height: calc(100% + 5rem);
  background-image: url("./../images/newsletter/newsletter-2.png");
  background-size: contain;
  background-position: bottom left;
  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  .newsletter-wrap::before,
  .newsletter-wrap::after {
    display: none;
  }
}

.newsletter-inner {
  width: 50%;
  margin: auto;
  max-width: 40rem;
}

.newsletter-inner h4 {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.3rem;
}

.newsletter-inner p {
  max-width: 30rem;
  text-transform: uppercase;
  color: #545454;
  font-weight: 500;
  font-size: 1.2rem;
}

.newsletter-inner p strong {
  font-weight: 900;
}

.newsletter-inner input {
  width: calc(100% - 11rem);
  border: 1px solid black;
  border-radius: 0.8rem;
  background: transparent;
  padding: 0.5rem 1.3rem;
  font-size: 1.2rem;
}

.newsletter-inner button {
  width: 10rem;
}

@media (max-width: 991px) {
  .newsletter-inner {
    max-width: 100%;
    width: 50rem;
  }
}

@media (max-width: 575px) {
  .newsletter-inner {
    text-align: center;
  }

  .newsletter-inner p {
    margin: 0 auto 1rem auto;
  }

  .newsletter-inner .form-inner {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .newsletter-inner input {
    width: 100%;
  }

  .newsletter-inner button {
    width: 10rem;
    margin: 1rem auto 0 auto;
  }
}

.form-inner {
  display: flex;
  justify-content: space-between;
}

.blog-main {
  position: relative;
  background: url("./../images/blog-bg.jpg") no-repeat center top;
  background-size: cover;
}

.blog-main::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  height: 23rem;
  background-image: url("./../images/banner-overlay.png");
  z-index: 2;
}

@media (max-width: 767px) {
  .blog-main::after {
    background-position: right;
    height: 20rem;
  }
}

.blog-grid-home {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin: 0 -1rem;
  transform-style: preserve-3d;
}

.blog-grid-home .blog-grid-item {
  position: relative;
  margin: 2.5rem;
  width: calc(33.33% - 5rem);
  transform-style: preserve-3d;
  transition: transform 0.3s;
  -webkit-box-shadow: 0 0 20px 6px rgba(103, 103, 103, 0.3);
  -moz-box-shadow: 0 0 20px 6px rgba(103, 103, 103, 0.3);
  box-shadow: 0 0 20px 6px rgba(103, 103, 103, 0.3);
}

@media (max-width: 1199px) {
  .blog-grid-home .blog-grid-item {
    margin: 1rem;
    width: calc(33.33% - 2rem);
  }
}

@media (max-width: 991px) {
  .blog-grid-home .blog-grid-item {
    width: calc(50% - 2rem);
  }
}

@media (max-width: 575px) {
  .blog-grid-home .blog-grid-item {
    width: calc(100% - 2rem);
  }
}

.blog-grid-home .blog-grid-item .blog-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 97%, black 99%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 97%, black 99%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 97%, black 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#000000", GradientType=0);
}

.blog-grid-home .blog-grid-item h3 {
  text-transform: uppercase;
  font-size: calc(1.1rem + 1vw);
  text-align: center;
  font-weight: 800;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 3rem 1rem 1rem 1rem;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .blog-grid-home .blog-grid-item h3 {
    font-size: calc(1.1rem + 3vw);
  }
}

.blog-grid-home .blog-grid-item:hover {
  transform: translateY(1rem) scale(1.1);
}

@media (max-width: 991px) {
  .blog-grid-home .blog-grid-item:hover {
    transform: none;
  }
}

.blog-grid-home .blog-img {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 120%;
}

@media (max-width: 575px) {
  .blog-grid-home .blog-img {
    padding-top: 80%;
  }
}

.blog-grid-home .blog-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: cover;
  z-index: 1;
}

.blog-grid {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  margin: 0 auto;
}

.blog-grid.news .blog-grid-item {
  margin: 3%;
  width: calc(33.33% - 6%);
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  .blog-grid.news .blog-grid-item {
    margin: 1rem;
    width: calc(33.33% - 2rem);
  }
}

@media (max-width: 991px) {
  .blog-grid.news .blog-grid-item {
    width: calc(50% - 2rem);
  }
}

@media (max-width: 575px) {
  .blog-grid.news .blog-grid-item {
    width: calc(100% - 2rem);
  }
}

.blog-grid .blog-grid-item {
  position: relative;
  margin: 3%;
  width: calc(50% - 7%);
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  .blog-grid .blog-grid-item {
    margin: 1rem;
    width: calc(50% - 2rem);
  }
}

@media (max-width: 991px) {
  .blog-grid .blog-grid-item {
    width: calc(50% - 2rem);
  }
}

@media (max-width: 575px) {
  .blog-grid .blog-grid-item {
    width: calc(100% - 2rem);
  }
}

.blog-grid .blog-grid-item:hover .overlay {
  opacity: 1;
}

.blog-grid .blog-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.blog-grid .blog-title {
  margin: 1.4rem 0;
}

.blog-grid .blog-title h3 {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 800;
  color: #000000;
  margin-bottom: 0;
  max-width: 25rem;
}

.blog-grid .blog-img {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 70%;
}

.blog-grid .blog-img::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  height: 2rem;
  background-image: url("./../images/blog/blog-overlay.png");
  z-index: 2;
}

.blog-grid .blog-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.blog-grid .blog-img .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}

.blog-grid .blog-img .read-more {
  display: block;
  color: #ffffff;
  padding: 0.35rem 1.6rem;
  border: 1px solid #ffffff;
  transition: all 0.3s;
}

.blog-grid .blog-img .read-more:hover {
  background: #ffffff;
  color: #000000;
}

.blog-grid .blog-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-grid .blog-more span {
  font-weight: 600;
  font-size: 0.8rem;
}

.blog-grid .blog-views {
  display: flex;
  align-items: center;
}

.blog-grid .blog-views img {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}

.blog-grid .own-btn,
.blog-grid .own-btn--sm,
.blog-grid .own-btn--prev,
.blog-grid .own-btn--md-reversed {
  border-radius: 0.5rem;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  box-shadow: none;
}

.blog-grid .own-btn:hover,
.blog-grid .own-btn--sm:hover,
.blog-grid .own-btn--prev:hover,
.blog-grid .own-btn--md-reversed:hover {
  background: #454545;
  color: #ffffff;
}

.blog-grid .blog-header {
  padding: 1.5rem 0;
}

.blog-grid .blog-footer {
  margin-top: auto;
}

.blog-grid.with-padding .blog-grid-item {
  -webkit-box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.15);
  -moz-box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.15);
  box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.15);
}

.blog-grid.with-padding .blog-grid-item:hover {
  -webkit-box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.3);
  -moz-box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.3);
  box-shadow: 0 13px 17px -6px rgba(103, 103, 103, 0.3);
}

.blog-grid.with-padding .blog-header {
  padding: 2rem;
}

.blog-grid.with-padding .blog-footer {
  padding: 0 2rem 2rem 2rem;
}

.blog-tag {
  font-size: 0.9rem;
}

.blog-date {
  color: #999999;
}

.blog-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: #666666;
}

.blog-author img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 0.5rem;
}

.blog-articles-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}

.blog-articles-wrap .blog-content {
  width: calc(100% - 27%);
}

@media (max-width: 1366px) {
  .blog-articles-wrap .blog-content {
    width: calc(100% - 22rem);
  }
}

@media (max-width: 991px) {
  .blog-articles-wrap .blog-content {
    width: 100%;
  }
}

.blog-articles-wrap .blog-sidebar {
  width: 25%;
  margin-top: 3rem;
}

@media (max-width: 1366px) {
  .blog-articles-wrap .blog-sidebar {
    width: 20rem;
  }
}

@media (max-width: 991px) {
  .blog-articles-wrap .blog-sidebar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
  }

  .blog-articles-wrap .blog-sidebar > div {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .blog-articles-wrap .blog-sidebar {
    margin-top: 2rem;
    flex-direction: column;
  }

  .blog-articles-wrap .blog-sidebar > div {
    width: 100%;
    margin-bottom: 3rem;
  }
}

.blog-sidebar > div {
  margin-bottom: 6rem;
}

.blog-sidebar > div:last-child {
  margin-bottom: 0;
}

.instagram-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

.instagram-counter > div {
  margin: 0 0.5rem;
  text-align: center;
}

.instagram-counter span {
  font-weight: bold;
}

.instagram-posts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.instagram-item {
  width: calc(50% - 1rem);
  margin: 0.5rem;
}

.instagram-item .overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
}

.instagram-item .show {
  display: block;
  color: #ffffff;
  font-size: 0.85rem;
  padding: 0.3rem 1.2rem;
  border: 1px solid #ffffff;
  transition: all 0.3s;
}

.instagram-item .show:hover {
  background: #ffffff;
  color: #000000;
}

.instagram-item:hover .overlay {
  opacity: 1;
}

.instagram-img {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.instagram-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: cover;
  z-index: 1;
}

.follow-counters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  max-width: 20rem;
  margin: 0 auto;
}

.follow-item {
  width: calc(33.33% - 1rem);
  margin: 0 0.5rem;
}

.follow-item img {
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
}

.follow-item span {
  display: block;
  line-height: 1;
}

.follow-item .follow-item-count {
  font-weight: bold;
  font-size: 1.1rem;
}

@media (max-width: 991px) and (min-width: 768px) {
  .follow-item {
    font-size: 0.8rem;
  }
}

@media (max-width: 767px) {
  .favorite-counter {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .favorite-counter {
    flex-direction: column;
  }
}

.favorite-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.favorite-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .favorite-item {
    width: calc(50% - 2rem);
    margin: 1rem;
  }
}

@media (max-width: 575px) {
  .favorite-item {
    width: calc(100% - 2rem);
  }
}

.favorite-title {
  width: 75%;
  max-width: 25rem;
  margin-right: 2rem;
}

.favorite-title h5 {
  display: inline-block;
  font-weight: 900;
  font-size: 1.1rem;
  margin-bottom: 0;
}

.favorite-date {
  font-size: 0.7rem;
  text-align: right;
  display: block;
}

.favorite-img {
  position: relative;
  width: 25%;
  max-width: 7rem;
  flex-shrink: 0;
}

.favorite-img .img-wrap {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: calc(100% - 4px);
  border-radius: 50%;
  border: 2px solid #c2c2c2;
  overflow: hidden;
}

.favorite-img .img-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid white;
  text-align: center;
  object-fit: cover;
  z-index: 1;
}

.favorite-img .favorite-position {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  background: black;
  color: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: 2.8rem;
  font-weight: 800;
  text-align: center;
  z-index: 1;
}

.banner-blog-detail {
  background: #f7f5f5;
}

#blog-detail {
  position: relative;
  background: #f7f5f5;
}

#blog-detail:not(.without-img) {
  padding-top: 0;
}

#blog-detail:not(.without-img) .blog-detail-wrapper {
  margin-top: -10rem;
}

#blog-detail.without-img .blog-detail-wrapper {
  margin-top: 4rem;
}

#blog-detail::after {
  content: "";
  position: absolute;
  bottom: -4rem;
  left: 0;
  display: block;
  width: 100%;
  height: 4rem;
  background: #f7f5f5;
}

#blog-detail .birds,
#blog-detail .birds-3,
#blog-detail .birds-2,
#blog-detail .birds-1 {
  position: absolute;
  max-width: 50rem;
}

#blog-detail .birds-1 {
  top: 0;
  right: 0;
}

#blog-detail .birds-2 {
  top: 50%;
  left: 0;
  transform: translateY(-60%);
}

#blog-detail .birds-3 {
  bottom: 0;
  right: 0;
}

.blog-detail-wrapper {
  position: relative;
  background: white;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 3rem 3rem 3rem;
  z-index: 3;
  box-shadow: 0 0 30px 14px rgba(103, 103, 103, 0.2);
}

@media (max-width: 991px) {
  .blog-detail-wrapper {
    max-width: 50rem;
  }
}

@media (max-width: 575px) {
  .blog-detail-wrapper {
    padding: 1rem 2rem 2rem 2rem;
  }
}

.blog-detail-wrapper #page-breadcrumbs {
  margin-bottom: 5rem;
}

@media (max-width: 991px) {
  .blog-detail-wrapper #page-breadcrumbs {
    margin-bottom: 2rem;
  }
}

.blog-detail-wrapper .blog-tag {
  margin-bottom: 2rem;
}

.blog-detail-wrapper .blog-title {
  margin-bottom: 1rem;
}

.blog-detail-wrapper .blog-title h1 {
  font-size: 3rem;
  font-weight: 700;
  max-width: 50rem;
}

@media (max-width: 575px) {
  .blog-detail-wrapper .blog-title h1 {
    font-size: 2rem;
  }
}

@media (max-width: 425px) {
  .blog-detail-wrapper .blog-title h1 {
    font-size: 1.5rem;
  }
}

.blog-detail-wrapper .blog-perex {
  margin: 2rem 0 4rem 0;
}

.blog-detail-wrapper .blog-content img {
  margin: 1rem 0 2rem 0;
  max-width: 100%;
  height: auto;
}

.blog-detail-wrapper .blog-content h2 {
  font-size: 1.6rem;
  font-weight: 800;
  text-transform: none;
}

.blog-detail-wrapper .blog-content h3 {
  font-size: 1.3rem;
  font-weight: 800;
  text-transform: none;
}

.blog-detail-wrapper .blog-content h4 {
  font-size: 1.1rem;
  font-weight: 800;
  text-transform: none;
}

.heading-with-icon,
.heading-with-icon--xs,
.heading-with-icon--sm {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

@media (max-width: 575px) {
  .heading-with-icon,
  .heading-with-icon--xs,
  .heading-with-icon--sm {
    flex-direction: column;
    text-align: center;
  }
}

.heading-with-icon .icon-wrap,
.heading-with-icon--xs .icon-wrap,
.heading-with-icon--sm .icon-wrap {
  margin-left: 2rem;
  flex-shrink: 0;
}

@media (max-width: 575px) {
  .heading-with-icon .icon-wrap,
  .heading-with-icon--xs .icon-wrap,
  .heading-with-icon--sm .icon-wrap {
    order: 1;
    margin-left: 0;
    margin-bottom: 1rem;
  }
}

.heading-with-icon h2,
.heading-with-icon--xs h2,
.heading-with-icon--sm h2 {
  text-transform: lowercase;
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 0;
  border-right: 5px solid black;
  padding-right: 2rem;
}

.heading-with-icon h2 span,
.heading-with-icon--xs h2 span,
.heading-with-icon--sm h2 span {
  display: block;
  line-height: 1;
  font-size: 35%;
  letter-spacing: 6px;
}

@media (max-width: 575px) {
  .heading-with-icon h2,
  .heading-with-icon--xs h2,
  .heading-with-icon--sm h2 {
    order: 2;
    border: none;
    padding-right: 0;
    font-size: 3.5rem;
  }
}

.heading-with-icon img,
.heading-with-icon--xs img,
.heading-with-icon--sm img {
  height: 7rem;
  width: 7rem;
}

@media (max-width: 575px) {
  .heading-with-icon img,
  .heading-with-icon--xs img,
  .heading-with-icon--sm img {
    height: 6rem;
    width: 6rem;
  }
}

.heading-with-icon p,
.heading-with-icon--xs p,
.heading-with-icon--sm p {
  max-width: 24rem;
}

.heading-with-icon--sm h2 {
  font-size: 3.2rem;
  font-weight: 500;
  border-right: 3px solid black;
}

@media (max-width: 575px) {
  .heading-with-icon--sm h2 {
    font-size: 2.5rem;
    border: none;
  }
}

.heading-with-icon--sm h2 strong {
  font-weight: 900;
}

.heading-with-icon--sm img {
  width: 4rem;
  height: 4rem;
}

@media (max-width: 1199px) {
  .heading-with-icon--sm {
    justify-content: center;
  }
}

.heading-with-icon--xs {
  justify-content: center;
}

.heading-with-icon--xs h4 {
  text-transform: lowercase;
  font-size: 1.7rem;
  font-weight: 700;
  border-right: 3px solid black;
  padding-right: 1rem;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .heading-with-icon--xs h4 {
    font-size: 2.5rem;
    border: none;
    padding-right: 0;
    order: 2;
  }
}

.heading-with-icon--xs .icon-wrap {
  margin-left: 1rem;
}

@media (max-width: 575px) {
  .heading-with-icon--xs .icon-wrap {
    margin-left: 0;
  }
}

.heading-with-icon--xs img {
  width: 2.8rem;
  height: 2.8rem;
}

@media (max-width: 1199px) {
  #product-list .own-container-fluid {
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media (max-width: 991px) {
  #product-list .own-container-fluid {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.product-list-wrap {
  display: flex;
  justify-content: space-between;
}

.product-list-wrap .content {
  width: 100%;
  margin-left: 2rem;
  overflow: hidden;
}

@media (max-width: 991px) {
  .product-list-wrap .content {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}

.product-list-wrap .product-grid {
  justify-content: flex-start;
  margin: 0 -1rem;
}

.product-list-wrap .product-grid .product-grid-item {
  margin: 1rem;
  border-radius: 0;
  width: calc(25% - 2rem);
  border: 1px solid #e6e6e6;
  text-align: center;
}

@media (max-width: 1300px) {
  .product-list-wrap .product-grid .product-grid-item {
    width: calc(33.33% - 2rem);
  }
}

@media (max-width: 767px) {
  .product-list-wrap .product-grid .product-grid-item {
    width: calc(50% - 2rem);
  }
}

@media (max-width: 575px) {
  .product-list-wrap .product-grid .product-grid-item {
    width: calc(100% - 2rem);
  }
}

.product-list-wrap .product-grid .product-grid-item h3 {
  text-transform: unset;
  font-size: 0.9rem;
  font-weight: 700;
  background: transparent;
  color: #000000;
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.product-list-wrap .product-grid .product-grid-item:hover {
  -webkit-box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
  -moz-box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
  box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
}

.product-list-wrap .product-grid .product-grid-item:hover h3 {
  color: #000000;
}

.product-list-wrap .product-grid .product {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem 1.5rem 2rem 1.5rem;
}

.product-list-wrap .product-grid .product-img {
  padding-top: 80%;
}

.product-list-wrap .product-grid .product-available {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  display: block;
}

.product-list-wrap .product-grid .product-available span {
  color: #25a314;
}

.product-list-wrap .product-grid .product-available .quantity {
  color: #000000;
}

.product-list-wrap .product-grid .product-footer {
  margin-top: auto;
}

.product-list-wrap .product-grid .own-btn,
.product-list-wrap .product-grid .own-btn--sm,
.product-list-wrap .product-grid .own-btn--prev,
.product-list-wrap .product-grid .own-btn--md-reversed {
  font-size: 0.9rem;
  padding: 0.6rem 1rem;
  letter-spacing: 2px;
  border-radius: 0;
  box-shadow: none;
}

.product-list-wrap .product-grid .own-btn:hover,
.product-list-wrap .product-grid .own-btn--sm:hover,
.product-list-wrap .product-grid .own-btn--prev:hover,
.product-list-wrap .product-grid .own-btn--md-reversed:hover {
  background: #454545;
  color: #ffffff;
}

.product-grid-load-more {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}

@media (max-width: 991px) {
  .product-grid-load-more {
    width: 100%;
    margin-left: 0;
  }
}

.product-grid-counter {
  margin-top: 0.5rem;
}

.product-grid-counter .product-grid-count {
  font-weight: 800;
}

.product-grid-counter span {
  font-weight: 600;
}

#sidebar {
  width: 22rem;
}

#sidebar h4 {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2rem;
  display: inline-block;
  margin-bottom: 0;
  padding-right: 0.8rem;
  border-right: 1px solid black;
}

@media (max-width: 1199px) {
  #sidebar h4 {
    font-size: 1.7rem;
  }
}

@media (max-width: 767px) {
  #sidebar h4 {
    font-size: 1.8rem;
  }
}

#sidebar ul {
  list-style: none;
  padding: 0;
}

#sidebar .navigation {
  list-style: none;
  margin: 2rem 0;
  padding: 0;
}

@media (max-width: 1199px) {
  #sidebar .navigation {
    margin: 1.6rem 0;
  }
}

#sidebar .navigation > li > a {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.1rem;
}

@media (max-width: 1199px) {
  #sidebar .navigation > li > a {
    font-size: 1.9rem;
  }
}

@media (max-width: 767px) {
  #sidebar .navigation > li > a {
    font-size: 1.7rem;
  }
}

@media (max-width: 575px) {
  #sidebar .navigation > li > a {
    font-size: 1.6rem;
  }
}

#sidebar .navigation li {
  position: relative;
  padding-left: 1.5rem;
}

@media (max-width: 767px) {
  #sidebar .navigation li {
    padding-left: 1.25rem;
  }
}

#sidebar .navigation li:not(.category-parent) {
  padding-left: 1.5rem;
}

@media (max-width: 767px) {
  #sidebar .navigation li:not(.category-parent) {
    padding-left: 1.25rem;
  }
}

#sidebar .navigation li > ul {
  display: none;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

#sidebar .navigation li.active > .js-category-parent-icon {
  transform: rotate(90deg);
}

#sidebar .navigation li.active > a {
  font-weight: 800;
}

#sidebar .navigation li.active > ul {
  display: block;
}

#sidebar .navigation ul li {
  margin: 0.4rem 0;
}

#sidebar .navigation ul li .js-category-parent-icon {
  top: 0.3rem;
}

@media (max-width: 1199px) {
  #sidebar .navigation ul li .js-category-parent-icon {
    top: 0.2rem;
  }
}

#sidebar .navigation ul a {
  font-size: 1.6rem;
  text-transform: lowercase;
  font-weight: 400;
  line-height: 1;
}

@media (max-width: 1199px) {
  #sidebar .navigation ul a {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  #sidebar .navigation ul a {
    font-size: 1.4rem;
  }
}

@media (max-width: 991px) {
  #sidebar {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    height: 100%;
    z-index: 1003;
    width: auto;
    max-width: calc(100% - 4rem);
    padding: 0;
    box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.05);
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  #sidebar.open {
    transform: translateX(0);
  }

  #sidebar.open #sidebar-toggle svg {
    transform: rotate(180deg);
  }
}

@media (max-width: 575px) {
  #sidebar {
    width: 20rem;
  }
}

#sidebar .sidebar-inner {
  width: 100%;
}

@media (max-width: 991px) {
  #sidebar .sidebar-inner {
    height: 100%;
    padding: 2rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow: -moz-hidden-scrollable;
  }
}

@media (max-width: 575px) {
  #sidebar .sidebar-inner {
    padding: 1.5rem;
  }
}

#sidebar-toggle {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
  width: 3rem;
  height: 3rem;
  background: black;
  border-radius: 0 0.8rem 0.8rem 0;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  #sidebar-toggle {
    display: flex;
  }
}

#sidebar-toggle svg {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.3s;
}

.js-category-parent-icon {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s;
}

@media (max-width: 1199px) {
  .js-category-parent-icon {
    top: 0.8rem;
  }
}

@media (max-width: 767px) {
  .js-category-parent-icon {
    top: 0.75rem;
    width: 1rem;
    height: 1rem;
  }
}

@media (max-width: 575px) {
  .js-category-parent-icon {
    top: 0.6rem;
  }
}

#sidebar-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  z-index: 101;
}

#sidebar-overlay.open {
  transform: translateX(0);
}

@media (min-width: 992px) {
  #sidebar-overlay {
    display: none;
  }
}

#page-breadcrumbs {
  margin-bottom: 2rem;
}

#page-breadcrumbs ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 -0.5rem;
}

#page-breadcrumbs ul li {
  position: relative;
  margin: 0 0.5rem;
}

#page-breadcrumbs ul li::after {
  content: "";
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  background-image: url("./../images/icons/right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0.9rem;
  vertical-align: middle;
}

#page-breadcrumbs ul li:last-child::after {
  display: none;
}

#page-breadcrumbs ul img {
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 575px) {
  #page-breadcrumbs ul {
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  #page-breadcrumbs {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
}

.without-banner h1 {
  display: inline-block;
  font-size: 5rem;
  font-weight: 700;
  text-transform: lowercase;
  border-right: 5px solid #000000;
  padding-right: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 575px) {
  .without-banner h1 {
    width: 100%;
    display: block;
    font-size: 3.5rem;
    text-align: center;
    border-right: none;
    padding: 0;
  }
}

#range-slider-wrap {
  max-width: 23rem;
}

#range-slider-inner {
  margin: 2rem 0;
}

@media (max-width: 1199px) {
  #range-slider-inner {
    margin: 1.6rem 0;
  }
}

#range-slider {
  width: calc(100% - 34px);
  margin: auto;
  border-radius: 0;
}

#range-slider .noUi-connects {
  border-radius: 0;
}

#range-slider .noUi-handle:before,
#range-slider .noUi-handle:after {
  display: none;
}

#range-slider .noUi-connect {
  background-color: #cccccc;
}

.range-slider-item {
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 0.3rem;
}

@media (max-width: 1199px) {
  .range-slider-item {
    font-size: 1.1rem;
  }
}

.range-slider-item::before {
  content: "€";
  display: inline-block;
  margin-right: 0.3rem;
}

.noUi-horizontal .noUi-handle {
  border: none;
  background: black;
  border-radius: 0;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle:focus {
  outline: none;
}

.category-carousel {
  margin: 3rem 0;
}

.swiper-button {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 50%;
}

.swiper-button svg {
  width: 1.7rem;
  height: 1.7rem;
}

.swiper-button:focus {
  outline: none;
}

@media (max-width: 991px) {
  .swiper-button {
    display: none;
  }
}

.swiper-button-prev {
  background: transparent;
}

.swiper-button-prev svg {
  margin-left: 0.05rem;
  margin-top: 0.15rem;
  transform-origin: center;
  transform: rotate(180deg);
}

.swiper-button-next {
  background: transparent;
}

.swiper-button-next svg {
  margin-left: 0.3rem;
  margin-top: 0.1rem;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.badges {
  text-align: center;
}

@media (max-width: 767px) {
  .badges {
    width: 100%;
  }
}

.badge-toggle,
.badge-toggle-news,
.badge-toggle-sale,
.badge-toggle-action,
.badge-toggle-tip {
  display: inline-block;
  padding: 0 1rem;
  min-width: 7.5rem;
  margin: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  border: 1px solid black;
  transition: color 0.3s, background 0.3s;
}

.badge-toggle:hover,
.badge-toggle-news:hover,
.badge-toggle-sale:hover,
.badge-toggle-action:hover,
.badge-toggle-tip:hover {
  background: black;
  color: #ffffff;
}

.badge-toggle-news {
  color: #88c56c;
  border-color: #88c56c;
}

.badge-toggle-news:hover {
  background: #88c56c;
}

.badge-toggle-sale {
  color: #ffc000;
  border-color: #ffc000;
}

.badge-toggle-sale:hover {
  background: #ffc000;
}

.badge-toggle-action {
  color: #ff0b0b;
  border-color: #ff0b0b;
}

.badge-toggle-action:hover {
  background: #ff0b0b;
}

.badge-toggle-tip {
  color: #46cacc;
  border-color: #46cacc;
}

.badge-toggle-tip:hover {
  background: #46cacc;
}

.product-badges {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  z-index: 1;
}

.product-badge,
.product-badge-news,
.product-badge-sale,
.product-badge-action,
.product-badge-tip {
  display: block;
  padding: 0 0.3rem;
  font-size: 0.9rem;
  margin: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  border-radius: 1rem;
  border: 1px solid black;
  color: transparent;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  transition: width 0.3s, color 0.3s;
}

.product-badge:hover,
.product-badge-news:hover,
.product-badge-sale:hover,
.product-badge-action:hover,
.product-badge-tip:hover {
  width: 5rem;
  color: #ffffff;
}

.product-badge-news {
  background: #88c56c;
  border-color: #88c56c;
}

.product-badge-sale {
  background: #ffc000;
  border-color: #ffc000;
}

.product-badge-action {
  background: #ff0b0b;
  border-color: #ff0b0b;
}

.product-badge-tip {
  background: #46cacc;
  border-color: #46cacc;
}

.js-addToCart.active .add-to-cart-btn-text {
  opacity: 0;
}

.js-addToCart.active .add-to-cart-btn-loader {
  opacity: 1;
}

.js-addToCart.added-to-cart .add-to-cart-btn-text {
  opacity: 1;
}

.js-addToCart.added-to-cart .add-to-cart-btn-loader {
  opacity: 0;
}

.js-addToCart.added-to-cart .add-to-cart-btn-text-after {
  transform: translate(-50%, -100%);
  opacity: 1;
  visibility: visible;
  pointer-events: none;
}

.add-to-cart-btn-text {
  transition: opacity 0.3s;
}

.add-to-cart-btn-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem;
  height: 1.5rem;
  max-width: 100%;
  max-height: 100%;
  background-image: url("./../images/icons/loader.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.3s;
  opacity: 0;
}

.add-to-cart-btn-text-after {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  background: white;
  font-size: 0.8rem;
  letter-spacing: 0;
  font-weight: 500;
  white-space: normal;
  width: 10rem;
  color: black;
  padding: 0.5rem;
  transform: translate(-50%, -150%);
  border-radius: 0.5rem;
  box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.pagination {
  transition: all 0.3s;
  display: inline-flex;
}

.pagination .page-item .page-link {
  color: #000000;
  padding: 0.8em 1em;
  transition: all 0.3s;
}

.pagination .page-item.active .page-link {
  color: #ffffff;
  background: #000000;
  border-color: #000000;
}

.pagination .page-item:hover .page-link {
  color: #000000;
  background: #ffcf37;
  border-color: #ffcf37;
}

.pagination .page-item.disabled {
  cursor: auto;
  opacity: 0.5;
}

.pagination .page-item.disabled:hover .page-link {
  color: #000000;
  background: transparent;
  border-color: #dee2e6;
}

.no-js [data-lazyload] {
  display: none;
}

#tabs {
  padding: 2rem 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.tabs-header {
  display: flex;
}

.tab-title {
  padding: 1rem 2rem;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  margin-bottom: -1px;
  transition: all 0.3s;
}

.tab-title > * {
  margin: 0;
  font-size: 1.1rem;
}

.tab-title.active {
  background: #fafafa;
  border-color: #f2f2f2;
  border-bottom: 1px solid #fafafa;
}

.tabs-body {
  position: relative;
}

.tabs-body::before {
  content: "";
  position: absolute;
  left: -50vw;
  width: 150vw;
  top: 0;
  bottom: 0;
  background: #fafafa;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  z-index: -1;
}

.tab-content {
  padding: 2rem;
  display: none;
}

.tab-content.active {
  display: block;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.tooltip-icon {
  width: 1.5rem;
  height: 1.5rem;
}

[role~=tooltip][data-microtip-position|=top]::after {
  width: 100vw;
  max-width: 9rem;
  white-space: normal;
  text-align: center;
}

.subcategories-wrap {
  background-color: #f8f8f8;
}

.subcategories {
  text-align: center;
}

.subcategories a {
  display: inline-block;
  font-weight: 600;
  padding: 1.25rem 1.5rem;
  text-align: center;
  color: #000000;
  transition: background-color 0.3s;
}

@media (max-width: 575px) {
  .subcategories a {
    display: block;
    padding: 1.5rem 1rem;
  }
}

.subcategories a.active {
  font-weight: 800;
  background: #000000;
  color: #ffffff;
}

.subcategories a:not(.active):hover {
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
}

.subcategories h2 {
  font-size: 1.2rem;
  font-weight: inherit;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 0;
  color: inherit;
}

@media (max-width: 575px) {
  .subcategories h2 {
    font-size: 1.1rem;
  }
}

.technology-wrap {
  margin-top: 4rem;
}

.technology-wrap img {
  width: 100%;
  object-fit: cover;
}

.technology-wrap h3 {
  font-weight: 700;
}

.technology-wrap span {
  font-weight: 600;
}

.technology-wrap p {
  max-width: 40rem;
}

@media (min-width: 768px) {
  .technology-wrap .row:nth-child(odd) > div:nth-child(1) {
    order: 2;
  }

  .technology-wrap .row:nth-child(odd) > div:nth-child(2) {
    order: 1;
  }
}

/*Animations*/

@-webkit-keyframes fadeInAlert {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }

  20%, 80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}

@keyframes fadeInAlert {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }

  20%, 80% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(200%);
    transform: translateY(200%);
  }
}

.home-1 {
  background: url("./../images/home-1.jpg") no-repeat 15% top;
  background-size: cover;
}

@media (max-width: 991px) {
  .home-1 .banner-content {
    padding: 5rem 0;
  }
}

.home-1 .content {
  max-width: 30rem;
  margin-top: 5rem;
  margin-left: 5rem;
}

@media (max-width: 991px) {
  .home-1 .content {
    margin-top: 3rem;
    margin-left: 3rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 575px) {
  .home-1 .content {
    text-align: center;
    margin-left: 0;
  }
}

.home-1 h1 {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 1;
  margin-left: -3px;
}

.home-1 h1 span {
  display: block;
  font-size: 2.5rem;
  letter-spacing: 12px;
  line-height: 1;
  margin-left: 3px;
}

.home-2 .content {
  max-width: 30rem;
  margin-top: 6rem;
}

@media (max-width: 575px) {
  .home-2 .content {
    margin-top: 3rem;
    text-align: center;
  }
}

.home-2 .img-bg-wrap img {
  object-position: left bottom;
}

@media (max-width: 767px) {
  .home-2 {
    min-height: auto;
  }
}

.img-floated {
  position: absolute;
  top: 26%;
  left: 0;
  width: 45rem;
  max-width: 100%;
  z-index: -1;
}

@media (max-width: 767px) {
  .img-floated {
    display: none;
  }
}

.home-3 .content {
  max-width: 30rem;
  margin-top: 6rem;
}

@media (max-width: 575px) {
  .home-3 .content {
    margin-top: 0;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .home-3 {
    min-height: auto;
    margin-bottom: 6rem;
  }
}

@media (max-width: 575px) {
  .home-3 {
    margin-bottom: 0;
  }
}

.img-bg-wrap {
  position: absolute;
  top: 0;
  left: 30rem;
  width: calc(100% - 30rem);
  height: 100%;
}

.img-bg-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left top;
}

@media (max-width: 767px) {
  .img-bg-wrap {
    display: none;
  }
}

#blog-home {
  padding-top: 0;
}

/*Mixins*/

.eshop #nav-icon,
.eshop-home #nav-icon {
  right: auto;
  left: 3rem;
}

.eshop #burger-menu,
.eshop-home #burger-menu {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.eshop #burger-menu .burger-menu-inner,
.eshop-home #burger-menu .burger-menu-inner {
  padding: 4rem 0;
}

.eshop #desktop-menu > li a,
.eshop-home #desktop-menu > li a {
  font-size: 1.77rem;
  letter-spacing: 5px;
}

.eshop #desktop-menu .menu-dropdown li a,
.eshop-home #desktop-menu .menu-dropdown li a {
  font-size: 1rem;
  color: inherit;
  letter-spacing: 1px;
}

.eshop #desktop-menu .menu-dropdown li:hover a,
.eshop-home #desktop-menu .menu-dropdown li:hover a {
  color: inherit;
  opacity: 0.7;
}

.eshop .menu-dropdown,
.eshop-home .menu-dropdown {
  color: #ffffff;
  background: rgba(146, 146, 146, 0.89);
}

.divider {
  width: 100%;
  border-bottom: 1px solid black;
  display: block;
  opacity: 0.1;
  margin: 10px 0;
}

/*Mixins*/

.product-grid {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  margin: 0 -0.5rem;
}

.product-grid .product-grid-item {
  background: white;
  margin: 0.5rem;
  border-radius: 1.5rem;
  transition: box-shadow 0.3s;
  width: calc(33.33% - 1rem);
}

@media (max-width: 767px) {
  .product-grid .product-grid-item {
    width: calc(50% - 1rem);
  }
}

@media (max-width: 575px) {
  .product-grid .product-grid-item {
    width: calc(100% - 1rem);
  }
}

.product-grid .product-grid-item h3 {
  text-transform: uppercase;
  font-size: 1.2rem;
  background: black;
  color: white;
  display: inline-block;
  padding: 0.4rem 0.4rem 0.2rem 0.4rem;
  margin-top: 1rem;
  margin-bottom: 0;
  transition: color 0.3s;
}

.product-grid .product-grid-item:hover {
  -webkit-box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
  -moz-box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
  box-shadow: 0 0 15px 2px rgba(103, 103, 103, 0.16);
}

.product-grid .product-grid-item:hover h3 {
  color: #ffe765;
}

.product {
  position: relative;
  padding: 1rem 2rem 2rem 2rem;
}

.product-img {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 60%;
}

.product-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: contain;
  z-index: 1;
}

.product-price {
  font-size: 1.4rem;
  font-weight: 800;
  color: inherit;
  white-space: nowrap;
}

.product-price .old-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: #545454;
}

.product-add-to-cart {
  margin-top: 1rem;
}

#cart-steps {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 4rem;
}

@media (max-width: 767px) {
  #cart-steps {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }
}

#cart-steps .cart-steps-item {
  text-align: center;
  margin: 0 1rem;
}

#cart-steps .cart-steps-item.active .cart-steps-item-number {
  background: #2b2b2b;
  color: #ffffff;
}

#cart-steps .cart-steps-item.active img {
  filter: invert(1);
}

#cart-steps .cart-steps-item-number {
  width: 3.75rem;
  height: 3.75rem;
  line-height: 3.75rem;
  background: #ffffff;
  color: #2b2b2b;
  font-size: 1.1rem;
  font-weight: 900;
  border-radius: 1.1rem;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.22);
  margin: auto;
}

#cart-steps .cart-steps-item-number .a {
  display: block;
}

#cart-steps .cart-steps-item-number.disabled {
  background: #D9D9D9;
}

#cart-steps img {
  width: 2rem;
  height: 2rem;
}

.text-stroke {
  text-decoration: line-through;
}

#cart {
  max-width: 100rem;
  margin: auto;
  padding-top: 9rem;
}

@media (max-width: 991px) {
  #cart {
    padding-top: 7rem;
  }
}

#cart-product-list {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 2rem;
}

.cart-product {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.2rem 0;
  color: #2b2b2b;
  margin-bottom: 0.5rem;
}

.cart-product:last-child {
  margin-bottom: 0;
}

.cart-product::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2rem;
  right: 0;
  width: calc(100% + 4rem);
  height: 100%;
  border-bottom: 1px solid #d9d9d9;
  z-index: -1;
}

.cart-product.no-image .cart-product-img {
  height: 5rem;
}

.cart-product.no-image .cart-product-title h3 {
  font-size: 1rem;
}

.cart-product.no-image .cart-product-price-total {
  font-size: 1.1rem;
}

@media (max-width: 1199px) {
  .cart-product {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .cart-product {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .cart-product .cart-product-price-total {
    margin-bottom: 1rem;
  }

  .cart-product.no-image .cart-product-img {
    display: none;
  }

  .cart-product.no-image .cart-product-quantity {
    display: none;
  }
}

.cart-product-img {
  width: 9rem;
  height: 9rem;
  background: white;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.cart-product-title {
  margin-right: auto;
  margin-left: 1.7rem;
}

.cart-product-title h3 {
  font-size: 1.1rem;
  font-weight: 900;
  line-height: 1;
  color: inherit;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .cart-product-title {
    margin: 1rem auto;
    text-align: center;
  }
}

.cart-product-quantity {
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  min-width: 8rem;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .cart-product-quantity {
    justify-content: center;
    margin: 0.5rem 0 1rem 0;
  }
}

.cart-product-quantity input {
  font-weight: inherit;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-left: none;
  border-right: none;
  border-radius: 0;
  max-width: 3rem;
  padding: 0.5rem;
  box-shadow: none;
  background: #ffffff;
}

.cart-product-quantity input:focus {
  outline: none;
  box-shadow: none;
}

.cart-product-quantity-nav {
  display: flex;
  justify-content: center;
}

.cart-product-quantity-nav button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: inherit;
  text-align: center;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  width: 2rem;
  padding: 0;
  background: white;
  box-shadow: none;
}

.cart-product-quantity-nav button:focus {
  outline: none;
  border: 1px solid #b3b3b3;
  box-shadow: none;
}

.cart-product-quantity-nav button img {
  width: 1.1rem;
  height: 1.1rem;
}

.cart-product-quantity-nav button:first-child img {
  transform: rotate(-45deg);
  width: 0.7rem;
}

.cart-product-quantity-nav button:last-child img {
  transform: rotate(45deg);
  width: 0.7rem;
}

@media (max-width: 767px) {
  .cart-product-quantity-nav {
    flex-direction: row;
  }
}

.cart-product-price,
.cart-product-price-total {
  font-size: 1.1rem;
  font-weight: 500;
  min-width: 6rem;
  margin-left: 1rem;
  white-space: nowrap;
  text-align: right;
}

@media (max-width: 767px) {
  .cart-product-price,
  .cart-product-price-total {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }
}

.cart-product-price-total {
  font-size: 1.3rem;
  font-weight: 600;
}

.cart-product-remove {
  cursor: pointer;
  margin-left: 3rem;
  border-radius: 50%;
  overflow: hidden;
  min-width: 2rem;
}

.cart-product-remove img {
  border-radius: 50%;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;
  transition: all 0.3s;
}

.cart-product-remove:hover img {
  background: #e0e0e0;
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  .cart-product-remove {
    position: absolute;
    top: 1rem;
    right: -1rem;
  }
}

.cart-voucher {
  font-size: 1.1rem;
  color: black;
  font-weight: 800;
  text-align: right;
  width: 100%;
  margin-top: 4rem;
  line-height: 1;
}

.cart-voucher .cart-voucher-input-group {
  display: flex;
  justify-content: center;
  max-width: 25rem;
}

.cart-voucher .cart-voucher-input-group .form-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.cart-voucher .cart-voucher-input-group .cart-voucher-send {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: inherit;
  text-align: center;
  border: none;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  padding: 0.5rem 1rem;
  box-shadow: none;
  letter-spacing: 0;
  background: #000000;
  color: #ffffff;
}

.cart-total {
  font-size: 1.5rem;
  color: black;
  font-weight: 800;
  text-align: right;
  width: 100%;
  margin-top: 4rem;
  line-height: 1;
}

.cart-total span {
  font-size: 1.7rem;
  font-weight: 900;
  letter-spacing: 0;
  white-space: nowrap;
}

.cart-total .cart-total-notax {
  font-size: 1.2rem;
  opacity: 0.8;
  margin-bottom: 0.5rem;
}

.cart-total .cart-total-notax span {
  font-size: 1.4rem;
  display: inline-block;
  min-width: 150px;
}

.cart-total .cart-total-hero span {
  display: inline-block;
  min-width: 150px;
}

@media (max-width: 767px) {
  .cart-total {
    width: 100%;
    padding-right: 0;
    text-align: center;
    margin-bottom: 1rem;
  }

  .cart-total .cart-total-notax span,
  .cart-total .cart-total-hero span {
    display: block;
    margin-top: 10px;
  }

  .cart-total .cart-total-notax {
    margin-bottom: 15px;
  }
}

.cart-nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .cart-nav {
    width: 100%;
    padding-right: 0;
    flex-direction: column;
    justify-content: center;
  }

  .cart-nav div {
    margin: 0.5rem 0;
  }

  .cart-nav div:nth-child(1) {
    order: 2;
  }
}

.cart-nav .own-btn:hover,
.cart-nav .own-btn--sm:hover,
.cart-nav .own-btn--prev:hover,
.cart-nav .own-btn--md-reversed:hover {
  background-color: #ffcf37;
  color: #ffffff;
}

.grey-box {
  position: relative;
}

.grey-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: #f6f8fa;
  z-index: -1;
}

@media (max-width: 991px) {
  .grey-box::before {
    right: -5vw;
    width: 110vw;
  }
}

#cart-payment h2 {
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: none;
  letter-spacing: 1px;
  line-height: 1;
  color: #2b2b2b;
  margin-bottom: 2rem;
}

@media (max-width: 575px) {
  #cart-payment h2 {
    text-align: center;
  }
}

#cart-payment .form-check label {
  padding-left: 1.5rem;
  cursor: pointer;
}

#cart-payment .form-check input[type=radio],
#cart-payment .form-check input[type=checkbox] {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

#cart-payment .form-check input[type=radio]:checked ~ .check,
#cart-payment .form-check input[type=checkbox]:checked ~ .check {
  background: #03a189;
  border-color: #03a189;
}

#cart-payment .form-check input[type=radio]:checked ~ label span,
#cart-payment .form-check input[type=checkbox]:checked ~ label span {
  opacity: 1;
}

#cart-payment .form-check .check {
  position: absolute;
  top: 50%;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  background: #ffffff;
  border: 1px solid #b3b3b3;
  border-radius: 0.4rem;
  transition: background-color 0.3s;
  transform: translateY(-50%);
  cursor: pointer;
}

#cart-payment .form-check-value,
#cart-payment .form-check-value-free {
  text-transform: uppercase;
  font-weight: 800;
  white-space: nowrap;
  margin-left: 1rem;
}

#cart-payment .form-check-value-free {
  color: #03a189;
}

#cart-payment .cart-payment-item:nth-child(2) {
  margin-top: 2rem;
}

#cart-payment .profile-card .card-title {
  line-height: 1;
  font-size: 1rem;
}

#cart-summary-wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  background: #f9f9f9;
}

.cart-summary {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

@media (max-width: 575px) {
  .cart-summary {
    margin-bottom: 0.5rem;
  }
}

.cart-summary-title h3 {
  text-transform: none;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.2;
  color: #2b2b2b;
  margin-bottom: 0;
}

.cart-summary-title h3 span {
  display: block;
  font-weight: 800;
}

.cart-summary-price {
  width: 9rem;
  text-align: right;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
  white-space: nowrap;
}

.cart-summary-total {
  margin-bottom: 0;
}

.cart-summary-total .cart-summary-price {
  font-size: 1.7rem;
  font-weight: 900;
  width: auto;
  margin-left: auto;
}

.cart-delivery-info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

.cart-delivery-info .cart-delivery-info-item-col,
.cart-delivery-info .cart-delivery-info-item-col-w100 {
  width: calc(50% - 1rem);
}

@media (max-width: 575px) {
  .cart-delivery-info .cart-delivery-info-item-col,
  .cart-delivery-info .cart-delivery-info-item-col-w100 {
    width: 25rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.cart-delivery-info .cart-delivery-info-item-col-w100 {
  width: 100%;
}

@media (max-width: 575px) {
  .cart-delivery-info .cart-delivery-info-item-col-w100 {
    width: 25rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.cart-delivery-info .cart-delivery-info-item {
  width: 100%;
  margin-bottom: 2rem;
}

.cart-delivery-info .form-control {
  border-color: #b3b3b3;
}

.cart-delivery-info .form-control.placeholder {
  color: #000000;
  text-transform: none;
  font-weight: 600;
  opacity: 0.4 !important;
  letter-spacing: 1px;
}

.cart-delivery-info .form-control:-moz-placeholder {
  color: #000000;
  text-transform: none;
  font-weight: 600;
  opacity: 0.4 !important;
  letter-spacing: 1px;
}

.cart-delivery-info .form-control::-moz-placeholder {
  color: #000000;
  text-transform: none;
  font-weight: 600;
  opacity: 0.4 !important;
  letter-spacing: 1px;
}

.cart-delivery-info .form-control:-ms-input-placeholder {
  color: #000000;
  text-transform: none;
  font-weight: 600;
  opacity: 0.4 !important;
  letter-spacing: 1px;
}

.cart-delivery-info .form-control::-webkit-input-placeholder {
  color: #000000;
  text-transform: none;
  font-weight: 600;
  opacity: 0.4 !important;
  letter-spacing: 1px;
}

.cart-delivery-info .sr-only {
  position: absolute;
}

.cart-delivery-extra-item {
  display: none;
  overflow: hidden;
  transition: all 0.3s;
}

.cart-delivery-extra-item .cart-delivery-info {
  padding-top: 1.5rem;
}

#cart-thankyou {
  text-align: center;
  margin-top: 8rem;
}

#cart-thankyou .cart-thankyou-text {
  font-size: 1.1rem;
  font-weight: 500;
}

#cart-thankyou .cart-nav {
  justify-content: center;
}

.category-glasses {
  position: relative;
  background: url("./../images/okuliare-bg.jpg") no-repeat center top;
  background-size: cover;
}

.category-glasses::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  height: 22rem;
  background-image: url("./../images/banner-overlay.png");
  z-index: 2;
}

@media (max-width: 767px) {
  .category-glasses::after {
    background-position: right;
    height: 20rem;
  }
}

.category-product-ordering {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -0.5rem;
}

.category-product-ordering .ordering {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

@media (max-width: 767px) {
  .category-product-ordering .ordering {
    width: 100%;
    justify-content: center;
    margin: 1rem 0;
  }
}

.category-product-ordering .ordering img {
  width: 0.8rem;
  height: 0.8rem;
}

.category-product-ordering .ordering > div {
  text-transform: lowercase;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0.5rem;
  cursor: pointer;
}

@media (max-width: 425px) {
  .category-product-ordering .ordering > div {
    width: 100%;
  }
}

.category-product-ordering .ordering > div img {
  transform: rotate(-90deg);
}

.category-product-ordering .ordering > div.active {
  font-weight: 900;
}

.category-product-ordering .ordering > div.active img {
  transform: rotate(90deg);
}

.category-carousel {
  margin: 3rem 0;
}

@media (max-width: 1199px) {
  .category-carousel {
    margin: 2rem 0;
  }
}

.category-carousel-item {
  position: relative;
  padding: 1rem;
}

.category-carousel-img {
  text-align: center;
  position: relative;
  width: 100%;
  padding-top: 60%;
}

.category-carousel-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  text-align: center;
  object-fit: contain;
  z-index: 1;
}

.category-carousel-title {
  text-align: center;
  margin-top: 1rem;
  text-transform: lowercase;
  color: #000000;
}

.category-carousel-title h3 {
  font-weight: 900;
  font-size: 1.5rem;
}

#product-detail-price {
  padding: 2rem 0;
}

.product-detail-dimensions .row {
  font-size: 1.05rem;
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #c2c2c2;
}

.product-detail-dimensions .row:last-child {
  border-bottom: none;
}

.product-add-to-cart-btn {
  display: inline-block;
  position: relative;
  text-transform: lowercase;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 1px;
  background: #03a189;
  color: #ffffff;
  padding: 0.5rem 1.2rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  box-shadow: none;
  white-space: nowrap;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.product-add-to-cart-btn:focus {
  outline: 0;
}

.product-add-to-cart-btn:hover {
  color: #ffffff;
  background-color: #04d3b4;
}

@media (max-width: 1199px) {
  .product-add-to-cart-btn {
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
  }
}

#product-detail .swiper-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#product-detail .swiper-slide {
  text-align: center;
}

#product-detail .swiper-slide img {
  width: 100%;
  height: 100%;
  max-width: 45rem;
  object-fit: contain;
}

#product-detail .gallery-top {
  height: 23rem;
  width: 100%;
}

#product-detail .gallery-thumbs {
  max-width: 50rem;
  height: 9rem;
  margin-top: 4rem;
}

@media (max-width: 575px) {
  #product-detail .gallery-thumbs {
    margin-top: 1rem;
    height: 11rem;
  }
}

@media (max-width: 425px) {
  #product-detail .gallery-thumbs {
    margin-top: 0;
    margin-bottom: 1rem;
    height: 8rem;
  }
}

@media (max-width: 375px) {
  #product-detail .gallery-thumbs {
    margin-top: 0;
    margin-bottom: 1rem;
    height: 10rem;
  }
}

#product-detail .gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.3s;
}

#product-detail .gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

#product-similar .product-grid-item {
  border: 1px solid #e6e6e6;
}

#product-similar .product-grid-item .product-img {
  padding-top: 80%;
}

#product-similar .product-grid-item h3 {
  text-transform: unset;
  font-size: 0.9rem;
  font-weight: 700;
  background: transparent;
  color: #000000;
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

#product-similar .product-grid-item:hover h3 {
  color: #000000;
}

#product-similar .product-grid-item .product-available {
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  display: block;
}

#product-similar .product-grid-item .product-available span {
  color: #25a314;
}

#product-similar .product-grid-item .product-available .quantity {
  color: #000000;
}

#product-similar .product-grid-item .product-footer {
  margin-top: auto;
}

#product-similar .product-grid-item .own-btn,
#product-similar .product-grid-item .own-btn--sm,
#product-similar .product-grid-item .own-btn--prev,
#product-similar .product-grid-item .own-btn--md-reversed {
  font-size: 0.9rem;
  padding: 0.6rem 1rem;
  letter-spacing: 2px;
  border-radius: 0;
  box-shadow: none;
}

#product-similar .product-grid-item .own-btn:hover,
#product-similar .product-grid-item .own-btn--sm:hover,
#product-similar .product-grid-item .own-btn--prev:hover,
#product-similar .product-grid-item .own-btn--md-reversed:hover {
  background: #454545;
  color: #ffffff;
}

#login {
  max-width: 100rem;
  margin: auto;
  padding-top: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

@media (max-width: 991px) {
  #login {
    padding-top: 7rem;
  }
}

#login h2 {
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: none;
  letter-spacing: 1px;
  line-height: 1;
  color: #2b2b2b;
  margin-bottom: 2.5rem;
}

@media (max-width: 575px) {
  #login h2 {
    text-align: center;
  }
}

@media (max-width: 575px) {
  #login .login-options {
    padding-top: 2rem;
  }
}

#login .g-signin2 > div {
  margin-left: auto;
}

@media (max-width: 575px) {
  #login .g-signin2 > div {
    margin: 0 auto;
  }
}

.login-box {
  max-width: 35rem;
  margin: 0 auto;
  width: 100%;
}

.login-extra {
  text-align: center;
  width: 100%;
  font-size: 0.95rem;
  margin: 2rem -0.5rem 1rem -0.5rem;
}

.login-extra a {
  margin: 0.5rem;
  text-decoration: underline;
  white-space: nowrap;
}

.login-options {
  margin: 0 -0.5rem;
}

.login-options .social-sign-in-wrap {
  margin: 0.35rem 0.5rem;
}

#register,
#profile {
  max-width: 100rem;
  margin: auto;
  padding-top: 9rem;
  overflow: hidden;
}

@media (max-width: 991px) {
  #register,
  #profile {
    padding-top: 7rem;
  }
}

#register h2,
#profile h2 {
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: none;
  letter-spacing: 1px;
  line-height: 1;
  color: #2b2b2b;
  margin-bottom: 2.5rem;
}

#register h2 small,
#profile h2 small {
  font-size: 70%;
  margin-top: 0.5rem;
}

@media (max-width: 575px) {
  #register h2:not(.js-toggle-exta),
  #profile h2:not(.js-toggle-exta) {
    text-align: center;
  }
}

#register .cart-delivery-extra-item .cart-delivery-info,
#profile .cart-delivery-extra-item .cart-delivery-info {
  padding-top: 0;
}

#register .g-signin2 > div,
#profile .g-signin2 > div {
  margin-right: auto;
}

@media (max-width: 575px) {
  #register .g-signin2 > div,
  #profile .g-signin2 > div {
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  #register .input-btn,
  #profile .input-btn {
    text-align: center;
  }
}

.js-toggle-exta {
  position: relative;
  cursor: pointer;
  padding-right: 2rem;
}

.js-toggle-exta::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.3s;
  background-image: url("./../images/icons/arrow-right.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.js-toggle-exta.active::after {
  transform: translateY(-50%) rotate(270deg);
}

.g-signin2 {
  width: 100%;
}

.profileSubnav {
  padding: 0;
  list-style: none;
}

#profile {
  padding-top: 0;
}

@media (max-width: 991px) {
  #profile {
    padding-top: 0;
  }
}

#profile.my-orders {
  max-width: 80rem;
}

#profile-nav {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

#profile-nav a {
  margin: 0.5rem 0.8rem;
}

#profile-subnav {
  list-style: none;
  padding: 0;
  margin: 0;
}

#profile-subnav li {
  border-bottom: 1px solid #dddddd;
}

#profile-subnav li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

#profile-subnav li.active {
  position: relative;
}

#profile-subnav li.active .item-name {
  font-weight: 800;
}

#profile-subnav li.active::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1rem;
  height: 1rem;
  border: 1px solid #c1c1c1;
  border-left: 0;
  border-bottom: 0;
  pointer-events: none;
  transform: translateY(-50%) rotate(45deg);
}

#profile-subnav li a {
  display: block;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
}

#profile-subnav .item-name {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
}

#profile-subnav .item-adress {
  display: block;
  font-size: 0.9rem;
  opacity: 0.8;
}

.order-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.4rem 0;
  color: #2b2b2b;
  border-bottom: 1px solid #ebebeb;
  transition: background-color 0.3s;
}

.order-wrapper:last-child {
  border-bottom: 0;
}

.order-wrapper:hover {
  background: #fcfcfc;
}

.order-wrapper div {
  padding: 0.5rem 1rem;
  width: 25%;
}

.order-wrapper span {
  display: block;
}

.order-wrapper .order-date,
.order-wrapper .order-price {
  text-align: right;
}

.order-wrapper .order-id {
  color: #adadad;
}

.order-wrapper .order-price {
  color: #8bc34a;
  font-weight: 700;
}

.order-wrapper .order-status {
  font-weight: 700;
}

.order-wrapper .order-status span {
  width: 6rem;
  text-align: center;
  padding: 0.6rem 0.3rem;
  border-radius: 10px;
  margin-left: auto;
  font-size: 0.9rem;
  line-height: 1;
}

.order-wrapper a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.order-wrapper .order-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.order-wrapper .order-item span:nth-child(1) {
  font-weight: 700;
  min-width: 8rem;
  width: 30%;
}

.order-wrapper .order-item .order-value {
  overflow-x: auto;
}

@media (max-width: 767px) {
  .order-wrapper {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0.8rem 0;
  }

  .order-wrapper .order-id,
  .order-wrapper .order-date,
  .order-wrapper .order-price,
  .order-wrapper .order-status {
    text-align: center;
  }

  .order-wrapper .order-status span {
    margin: 0 auto;
  }

  .order-wrapper div {
    width: 100%;
  }
}

.error-page {
  margin: 10rem auto;
  max-width: 70rem;
  position: relative;
  padding: 0 1rem;
}

.error-page h1 {
  font-size: 4.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.error-page h2 {
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.error-page img {
  max-width: 100%;
  height: auto;
  width: 20rem;
}

@media (max-width: 991px) {
  .error-page {
    margin: 4rem auto;
  }

  .error-page img {
    width: 15rem;
  }
}

.error-page a {
  display: inline-block;
  padding: 0.7rem 1.5rem;
  font-size: 1.1rem;
  background: #000000;
  color: #ffffff;
  margin-top: 1rem;
  transition: all 0.5s;
}

.error-page a:hover {
  background: #333333;
}

.document-section .h5 {
  margin-bottom: 1.2rem;
}

.document-section ol li,
.document-section ul li {
  margin-bottom: 1rem;
}

.document-section ol a,
.document-section ul a,
.document-section h1 a,
.document-section h2 a,
.document-section h3 a,
.document-section h4 a,
.document-section h5 a,
.document-section h6 a,
.document-section p a,
.document-section li a,
.document-section div a,
.document-section span a,
.document-section u a {
  color: #0056b3 !important;
}

.document-section ol a:hover,
.document-section ul a:hover,
.document-section h1 a:hover,
.document-section h2 a:hover,
.document-section h3 a:hover,
.document-section h4 a:hover,
.document-section h5 a:hover,
.document-section h6 a:hover,
.document-section p a:hover,
.document-section li a:hover,
.document-section div a:hover,
.document-section span a:hover,
.document-section u a:hover {
  text-decoration: underline !important;
}

.document-section a {
  color: #0056b3 !important;
}

.document-section a:hover {
  text-decoration: underline !important;
}

.scale-both {
  max-width: 100%;
  max-height: 100%;
}

