.without-banner {
    h1 {
        display: inline-block;
        font-size: 5rem;
        font-weight: 700;
        text-transform: lowercase;
        border-right: 5px solid $text-color;
        padding-right: 2rem;
        margin-bottom: 2rem;
        @media (max-width: 575px) {
            width: 100%;
            display: block;
            font-size: 3.5rem;
            text-align: center;
            border-right: none;
            padding: 0;
        }
    }
}
