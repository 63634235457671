#login {
    max-width: 100rem;
    margin: auto;
    padding-top: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    @media (max-width: 991px) {
        padding-top: 7rem;
    }
    h2 {
        font-size: 1.6rem;
        font-weight: 900;
        text-transform: none;
        letter-spacing: 1px;
        line-height: 1;
        color: $primary-color;
        margin-bottom: 2.5rem;
        @media (max-width: 575px) {
            text-align: center;
        }
    }
    .login-options {
        @media (max-width: 575px) {
            padding-top: 2rem;
        }
    }
    .g-signin2 > div{
        margin-left: auto;
        @media (max-width: 575px) {
            margin: 0 auto;
        }
    }
}
.login-box {
    max-width: 35rem;
    margin: 0 auto;
    width: 100%;
}
.login-extra {
    text-align: center;
    width: 100%;
    font-size: .95rem;
    margin: 2rem -.5rem 1rem -.5rem;
    a {
        margin: .5rem;
        text-decoration: underline;
        white-space: nowrap;
    }
}
.login-options {
    margin: 0 -.5rem;
    .social-sign-in-wrap {
        margin: .35rem .5rem;
    }
}
