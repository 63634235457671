/*Privacy policy*/
#personal-data {
  h3 {
    margin-top: 3rem;
    &:first-child {
      margin-top: 0;
    }
  }
  .first-level {
    padding-left: 30px;
  }
  .second-level {
    padding-left: 60px;
  }
  a {
    word-wrap: break-word;
  }
  .highlighted {
    font-style: italic;
    font-size: 1.5rem;
  }
}