.tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: .5rem;
}
.tooltip-icon {
    width: 1.5rem;
    height: 1.5rem;
}

[role~="tooltip"][data-microtip-position|="top"]::after {
    width: 100vw;
    max-width: 9rem;
    white-space: normal;
    text-align: center;
}
