@import '../variables';
@import '../mixins/transition';
@import '../mixins/flex';

/*General*/
html {
//    @media (max-width: 1920px) {
//        font-size: 100%;
//    }
//    @media (max-width: 1680px) {
//        font-size: 90%;
//    }
//    @media (max-width: 1600px) {
//        font-size: 85%;
//    }
//    @media (max-width: 1440px) {
//        font-size: 80%;
//    }
//    @media (max-width: 1366px) {
//        font-size: 75%;
//    }
    @media (max-width: 991px) {
        font-size: 90%;
    }
}
a {
    color: inherit;
    outline: 0;
    @include transition(color .3s);

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

body {
    @include flexbox();
    @include flex-flow(column);
    min-height: 100vh;
    background: $base-color;
    font-family: $base-font-family;
    font-size: 1rem;
    font-weight: 500;
    color: $text-color;
    overflow-x: hidden;
    min-width: 0;
}

h1, h2, h3, h4, h5 {
    color: $text-color;
}
h2 {
    text-transform: lowercase;
    font-size: 4.2rem;
    font-weight: 600;
    line-height: 1;
    span {
        display: block;
        line-height: 1;
        font-size: 35%;
        letter-spacing: 6px;
    }
}

main {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    @include flex-grow(1);
}

section {
    padding: $section-padding-md;
    @media (max-width: 991px) {
        padding: $section-padding-sm;
        &.without-banner {
            padding-top: 6rem;
        }
    }

}

textarea {
    resize: vertical;
}

