$counter-primary-bg: #8BC34A;
$counter-primary-color: #ffffff;

.cart {
    position: relative;
    margin: 0 .5rem 0 0;
    svg {
        width: 2rem;
        height: 2rem;
        color: inherit;
    }
    @media (max-width: 991px) {
        width: 2rem;
        svg {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
    .cart-counter {
        position: absolute;
        bottom: -0.9rem;
        right: -0.65rem;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.5rem;
        font-size: .75rem;
        font-weight: 700;
        text-align: center;
        border-radius: 50%;
        background: #8BC34A;
        transition: background .3s;
        color: $counter-primary-color;
        &.active {
            transition: transform .5s, background .3s;
            background: #f44336;
            transform: rotate(360deg);
        }
    }
}
