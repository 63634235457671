#newsletter {
    position: relative;
    background: #efefef;
    padding: 6rem 0;
    margin-top: 4rem;
    @media (max-width: 991px) {
        margin-top: 0;
        padding: 3rem 0;
    }
}
.newsletter-wrap {
    &::before {
        content: '';
        position: absolute;
        top: -5rem;
        right: 70%;
        width: 30rem;
        height: calc(100% + 5rem);
        background-image: url("../../images/newsletter/newsletter-1.png");
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat
    }
    &::after {
        content: '';
        position: absolute;
        top: -5rem;
        left: 70%;
        width: 30rem;
        height: calc(100% + 5rem);
        background-image: url("../../images/newsletter/newsletter-2.png");
        background-size: contain;
        background-position: bottom left;
        background-repeat: no-repeat
    }
    @media (max-width: 991px) {
        &::before,
        &::after {
            display: none;
        }
    }
}
.newsletter-inner {
    width: 50%;
    margin: auto;
    max-width: 40rem;
    h4 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 2.3rem;
    }
    p {
        max-width: 30rem;
        text-transform: uppercase;
        color: #545454;
        font-weight: 500;
        font-size: 1.2rem;
        strong{
            font-weight: 900;
        }
    }
    input {
        width: calc(100% - 11rem);
        border: 1px solid black;
        border-radius: .8rem;
        background: transparent;
        padding: 0.5rem 1.3rem;
        font-size: 1.2rem;
    }
    button {
        width: 10rem;
    }
    @media (max-width: 991px) {
        max-width: 100%;
        width: 50rem;
    }
    @media (max-width: 575px) {
        text-align: center;
        p {
            margin: 0 auto 1rem auto;
        }
        .form-inner {
            flex-wrap: wrap;
            flex-direction: column;
        }
        input {
            width: 100%;
        }
        button {
            width: 10rem;
            margin: 1rem auto 0 auto;
        }

    }
}

.form-inner {
    display: flex;
    justify-content: space-between;
}
