.technology-wrap {
    margin-top: 4rem;
    img {
        width: 100%;
        object-fit: cover;
    }
    h3 {
        font-weight: 700;
    }
    span {
        font-weight: 600;
    }
    p {
        max-width: 40rem;
    }
    @media (min-width: 768px) {
        .row {
            &:nth-child(odd) {
                & > div {
                    &:nth-child(1) {
                        order: 2;
                    }
                    &:nth-child(2) {
                        order: 1;
                    }
                }
            }
        }
    }
}

