.offer {
    position: relative;
    max-width: 60rem;
    margin: 1.5rem 0;
    @media (max-width: 991px) {
        max-width: 100%;
    }
    @media (max-width: 767px) {
        margin: 3rem 0;
    }
    @media (max-width: 575px) {
        text-align: center;
    }
    &-item {
        @include flexbox();
        @include justify-content(space-between);
        margin-top: 1.5rem;
        @media (max-width: 575px) {
            position: relative;
            @include flex-direction(column);
            @include align-items(center);
            margin-top: 4.5rem;
        }
        & > div {
            &:first-child {
                margin-right: .5rem;
                @media (max-width: 575px) {
                    margin-right: 0;
                }
            }
        }
    }
    .day {
        font-size: 2.65rem;
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 3px;
    }
    .name {
        font-size: 2.56rem;
        line-height: 1;
        color: $primary-color;
        margin-bottom: 0;
        max-width: 40rem;
    }
    .alergens {
        font-size: 1.9rem;
        color: lighten($primary-color, 20%);
    }
    .price {
        display: inline-block;
        font-size: 2.6rem;
        font-weight: 900;
        line-height: 1;
        letter-spacing: 2px;
        white-space: nowrap;
        @media (max-width: 575px) {
            margin-top: 1rem;
        }
    }
}

.offer-wrapper {
    @include flexbox();
    width: 100%;
    margin-top: 2.5rem;
    @media (max-width: 991px) {
        @include flex-wrap(wrap);
        margin-top: 4rem;
    }
    .offer {
        &::after {
            content: '';
            display: block;
            width: 12rem;
            height: 2px;
            max-width: 100%;
            margin: 4rem auto;
            background: $secondary-color;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
    }
    & > div {
        width: 50%;
        @media (max-width: 991px) {
            width: 100%;
        }
        &:first-child {
            padding-right: 3rem;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 2px;
                height: 100%;
                background: $secondary-color;
            }
            @media (max-width: 991px) {
                padding-right: 0;
                &::after {
                    display: none;
                }
            }
            .offer {
                &:last-child {
                    @media (max-width: 991px) {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
        &:last-child {
            padding-left: 3rem;
            @media (max-width: 991px) {
                padding-left: 0;
            }
        }
    }
}
