.page-title {
    h2,
    h1 {
        display: inline-block;
        font-size: 5rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0;
        @media (max-width: 1199px){
            font-size: 4rem;
        }
        @media (max-width: 575px){
            font-size: 3rem;
        }
        @media (max-width: 425px){
            display: block;
            font-size: 2rem;
            text-align: center;
        }
    }
}
