.badges {
    text-align: center;
    @media (max-width: 767px) {
        width: 100%;
    }
}
.badge-toggle {
    display: inline-block;
    padding: 0 1rem;
    min-width: 7.5rem;
    margin: .5rem;
    text-align: center;
    border-radius: 1rem;
    border: 1px solid black;
    transition: color .3s, background .3s;
    &:hover {
        background: black;
        color: #ffffff;
    }
    &-news,
    &-sale,
    &-action,
    &-tip {
        @extend .badge-toggle;
    }
    &-news {
        color: #88c56c;
        border-color: #88c56c;
        &:hover {
            background: #88c56c;
        }
    }
    &-sale {
        color: #ffc000;
        border-color: #ffc000;
        &:hover {
            background: #ffc000;
        }
    }
    &-action {
        color: #ff0b0b;
        border-color: #ff0b0b;
        &:hover {
            background: #ff0b0b;
        }
    }
    &-tip {
        color: #46cacc;
        border-color: #46cacc;
        &:hover {
            background: #46cacc;
        }
    }
}

.product-badges {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    z-index: 1;
}
.product-badge {
    display: block;
    padding: 0 .3rem;
    font-size: .9rem;
    margin: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    border-radius: 1rem;
    border: 1px solid black;
    color: transparent;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    transition: width .3s, color .3s;
    &:hover {
        width: 5rem;
        color: #ffffff;
    }
    &-news,
    &-sale,
    &-action,
    &-tip {
        @extend .product-badge;
    }
    &-news {
        background: #88c56c;
        border-color: #88c56c;
    }
    &-sale {
        background: #ffc000;
        border-color: #ffc000;
    }
    &-action {
        background: #ff0b0b;
        border-color: #ff0b0b;
    }
    &-tip {
        background: #46cacc;
        border-color: #46cacc;
    }
}
