@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
	-webkit-box-shadow: $top $left $blur $spread $color inset;
	-moz-box-shadow: $top $left $blur $spread $color inset;
	box-shadow: $top $left $blur $spread $color inset;
  } @else {
	-webkit-box-shadow: $top $left $blur $spread $color;
	-moz-box-shadow: $top $left $blur $spread $color;
	box-shadow: $top $left $blur $spread $color;
  }
}