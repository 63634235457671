@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
	$unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }

  -webkit-transition: $unfoldedTransitions;
  -o-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition ($transition) {
  // Default values
  $property: all;
  $duration: .2s;
  $easing: null; // Browser default is ease, which is what we want
  $delay: null; // Browser default is 0, which is what we want
  $defaultProperties: ($property, $duration, $easing, $delay);

  // Grab transition properties if they exist
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
	$p: null;
	@if $i <= length($transition) {
	  $p: nth($transition, $i)
	} @else {
	  $p: nth($defaultProperties, $i)
	}
	$unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}