#tabs {
    padding: 2rem 0;
    opacity: 0;
    transition: opacity .3s;
}
.tabs-header {
    display: flex;
}
.tab-title {
    padding: 1rem 2rem;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    margin-bottom: -1px;
    transition: all .3s;
    & > * {
        margin: 0;
        font-size: 1.1rem;
    }
    &.active {
        background: #fafafa;
        border-color: #f2f2f2;
        border-bottom: 1px solid #fafafa;
    }
}
.tabs-body {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: -50vw;
        width: 150vw;
        top: 0;
        bottom: 0;
        background: #fafafa;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        z-index: -1;
    }
}
.tab-content {
    padding: 2rem;
    display: none;

    &.active {
        display: block;
    }
}
