$start: 0;
$end: 9;

@for $i from $start through $end {
    .z-#{$i} {
        z-index: $i;
    }
}

.second-font-family {
    font-family: $second-font-family;
}
